import { useNavigate } from 'react-router-dom';
import { useCurrentRoles } from '@/shared/hooks/useCurrentRole';
import { checkRoleMatch } from '@/shared/components/check-internal-roles/CheckInternalRoles';
import { useUserInfo } from '@/contexts/userInfo';
import { DashboardAlertCards } from '../dashboard-alert-cards/DashboardAlertCards';
import DashboardRecordAlertCards from '../dashboard-record-alert-cards/DashboardRecordAlertCards';
import { useFlags } from 'launchdarkly-react-client-sdk';

function RoleBasedDashboardAlert(): JSX.Element {
  const currentRoles = useCurrentRoles();
  const { instructormyschedule } = useFlags();
  const navigate = useNavigate();
  const { isInternalUser } = useUserInfo();

  if (
    currentRoles &&
    currentRoles.length === 1 &&
    checkRoleMatch(currentRoles, 'CAE Instructor') &&
    isInternalUser
  ) {
    if (instructormyschedule) {
      navigate('/schedule');
    } else {
      navigate('/documents/overview');
    }
  }
  if (
    (currentRoles && checkRoleMatch(currentRoles as string[], 'Training')) ||
    isInternalUser ||
    checkRoleMatch(currentRoles as string[], 'Admin')
  ) {
    return <DashboardAlertCards />;
  }
  if (currentRoles && checkRoleMatch(currentRoles as string[], 'Records')) {
    return <DashboardRecordAlertCards />;
  } else {
    return <></>;
  }
}

export default RoleBasedDashboardAlert;
