import './DashboardTabs.css';
import { useCurrentRoles } from '@/shared/hooks/useCurrentRole';
import DashboardChartWrapper from '../dashboard-chart-wrapper/DashboardChartWrapper';

export function DashboardTabs(): JSX.Element {
  const currentRoles = useCurrentRoles();
  const isFinanceRole = currentRoles?.includes('Finance');
  const isInstructorRole = currentRoles?.includes('CAE Instructor');
  const isOnlyInstructor = currentRoles?.length === 1 && isInstructorRole;
  const isOnlyFinance = currentRoles?.length === 1 && isFinanceRole;

  return (
    <div className="dashboard-tabs">
      {!isOnlyFinance && !isOnlyInstructor ? (
        <>
          <p className="title-chart">
            {currentRoles &&
            !currentRoles.includes('Admin') &&
            !currentRoles.includes('Training') &&
            currentRoles.includes('Records')
              ? 'Your Records'
              : 'Your reservations'}
          </p>
          <DashboardChartWrapper />
        </>
      ) : null}
    </div>
  );
}

DashboardTabs.displayName = 'DashboardTabs';
