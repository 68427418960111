import { useHasPermission } from '@/shared/hooks/useHasPermission';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DirectoryButton } from '../directory-button/DirectoryButton';
import { DocumentLink } from '../document-link/DocumentLink';
import { DownloadDocumentAction } from '../download-document-action/DownloadDocumentAction';
import { DocumentElement } from '@/open-api';

interface Props {
  data: Array<DocumentElement>;
  onFolderClick: (id: string) => void;
}

export function DocumentsUpdatedListing({
  data = [],
  onFolderClick,
}: Readonly<Props>): JSX.Element {
  const navigate = useNavigate();
  const [selected, setSelected] = useState<string>('');

  const hasPermission = useHasPermission();
  const hasDownloadPermission = hasPermission('document_download');

  return (
    <div className="document-listing">
      <header>
        <h6>Name</h6>
      </header>
      <ul className="items">
        {data.map(d => (
          <li
            key={`row-${d.id}`}
            data-selected={d.id === selected ? 'true' : 'false'}
          >
            {d.type === 'folder' ? (
              <DirectoryButton
                data={{
                  id: d.id as string,
                  name: d.name as string,
                  numberOfItemsInside: d.count as number,
                }}
                onClick={e => {
                  e.preventDefault();
                  setSelected(d.id as string);
                }}
                onDoubleClick={() => onFolderClick(d.id as string)}
              />
            ) : (
              <DocumentLink
                data={d}
                onClick={e => {
                  e.preventDefault();
                  setSelected(d.id as string);
                  navigate(d.id as string);
                }}
              />
            )}
            {d.type === 'file' ? (
              <span className="actions">
                <DownloadDocumentAction
                  data={d}
                  permitted={hasDownloadPermission}
                />
              </span>
            ) : null}
          </li>
        ))}
      </ul>
    </div>
  );
}
