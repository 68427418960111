import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { Outlet, useNavigate } from 'react-router-dom';
import { InteractionType } from '@azure/msal-browser';
import { ApplicationFrame } from '@/features/frame';
import LoaderWithLabel from '../loader/Loader';
import { useEffect } from 'react';
import { useGetProfileQuery } from '../../api/useGetProfileQuery';

function Loader(): JSX.Element {
  return <LoaderWithLabel title="Loading..." />;
}

function PrivateRoute(): JSX.Element {
  const navigate = useNavigate();
  const { error, status } = useGetProfileQuery();
  useEffect(() => {
    if (status === 'error') {
      if (error?.message === 'Forbidden' || error?.message === 'Not Found') {
        navigate('/no-access-user');
      }
    }
  }, [status, error, navigate]);
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      loadingComponent={Loader}
    >
      <ApplicationFrame>
        <Outlet />
      </ApplicationFrame>
    </MsalAuthenticationTemplate>
  );
}

export default PrivateRoute;
