/* eslint import/no-unresolved: "off" */
import { useUserInfo } from '@/contexts/userInfo';
import { useMsal } from '@azure/msal-react';
import { AccessPassWidgetProps } from 'access_pass_mfe/AccessPassWidget';
import * as React from 'react';
import './AccessPassWidget.css';

function AccessPassWidget(): JSX.Element {
  const [RemoteComponent, setRemoteComponent] =
    React.useState<React.FC<AccessPassWidgetProps> | null>(null);
  const [loadError, setLoadError] = React.useState<Error | null>(null);

  React.useEffect(() => {
    const loadComponent = async (): Promise<void> => {
      try {
        const { default: Component } = await import(
          'access_pass_mfe/AccessPassWidget'
        );
        setRemoteComponent(() => Component);
      } catch (error) {
        console.error('Error loading remote component:', error);
        setLoadError(error as Error);
      }
    };

    loadComponent();
  }, []);

  const { instance } = useMsal();
  const userInfo = useUserInfo();

  return (
    <div className="host">
      {loadError ? (
        <div>
          Error loading the micro-frontend component. Please try again later.
        </div>
      ) : (
        <React.Suspense fallback={<div>Loading...</div>}>
          {RemoteComponent ? (
            <RemoteComponent msalInstance={instance} user={userInfo} />
          ) : (
            <div>Loading...</div>
          )}
        </React.Suspense>
      )}
    </div>
  );
}

export default AccessPassWidget;
