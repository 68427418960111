import { RelatedReservationEmptyState } from '@/features/reservation-details/components/empty-states/RelatedReservationEmptyState';
import { DataLoadingGrid, DataLoadingError } from '@/shared/components';
import { useViewportSize } from '@/shared/hooks/useViewportSize';
import { Tag, Icon } from '@cae/cobalt-react';
import { Link } from 'react-router-dom';
import useGetAwaitingDataQuery from '../../api/useGetAwaitingDataQuery';
import './DashboardNewAwaiting.css';

function calculateDueDays(dueDate: string): number {
  const due = new Date(dueDate);
  const today = new Date();
  const timeDiff = due.getTime() - today.getTime();
  return Math.ceil(timeDiff / (1000 * 60 * 60 * 24)); // Convert to days
}

export function DashboardNewAwaiting(): JSX.Element {
  const { data, status } = useGetAwaitingDataQuery();
  const { width } = useViewportSize();
  const notificationResponses = data?.notificationResponses;
  const invoicesUpcomingDueList = data?.invoicesUpcomingDueList;

  return (
    <section className="awaiting-info-new-action" data-testid="awaitingAction">
      <p className="title-awaiting">Awaiting your action</p>

      {status === 'loading' && (
        <DataLoadingGrid
          numberColumns={5}
          numberRows={6}
          style={{ marginTop: '1rem' }}
        />
      )}

      {status === 'error' && (
        <DataLoadingError heading="Error loading your next actions" />
      )}

      {status === 'success' &&
        (!notificationResponses?.items?.length &&
        !invoicesUpcomingDueList?.length ? (
          <RelatedReservationEmptyState>
            <h5>Good news!</h5>
            <p>
              Looks like there is nothing that requires your immediate attention
            </p>
          </RelatedReservationEmptyState>
        ) : (
          <ul className="dashboard-table-grid">
            {invoicesUpcomingDueList?.map(invoice => (
              <li key={`entry-${invoice.accountReceivableId}`}>
                <span className="awaiting-info-row-icon">
                  <Icon id="document-outline" />
                </span>
                <span className="awaiting-info-row-name">
                  <span>Payment for</span>
                  <span
                    className="reservation-id"
                    style={{ fontWeight: '500', margin: '0 4px' }}
                  >
                    transaction #{invoice.transactionNumber}
                  </span>
                  <span>
                    is <span>due soon.</span>
                  </span>
                </span>
                <span className="client-name">
                  <span>
                    Amount owed is: &nbsp;$
                    {new Intl.NumberFormat('en-US', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                    }).format(Number(invoice.amount)) ?? 'No Amount'}
                  </span>
                </span>
                <span className="status">
                  <Tag compact>
                    Due in:{' '}
                    {invoice.dueDate ? calculateDueDays(invoice.dueDate) : ''}{' '}
                    days.
                  </Tag>
                </span>
                <Link
                  to={`/finance/${invoice.accountReceivableId}`}
                  className="details-link"
                >
                  View Details
                  <Icon id="arrow-right-outline" size="sm" />
                </Link>
              </li>
            ))}
            {notificationResponses?.items?.map(d => (
              <li key={`entry-${d.id}`}>
                <span className="awaiting-info-row-icon">
                  <Icon id="file-outline" />
                </span>
                <span className="awaiting-info-row-name">
                  <span>Reservation</span>
                  <span
                    className="reservation-id"
                    style={{ fontWeight: '500', margin: '0 4px' }}
                  >
                    {d.schedulerReservationId}
                  </span>
                  <span>
                    has <span>missing documents</span>
                  </span>
                </span>
                <span className="client-name">
                  <span>Student: &nbsp;{d.name ?? 'No student assigned'}</span>
                  <span>
                    Course: &nbsp;{width <= 768 ? `${d.id} - ` : ''}
                    {d.courseName}
                  </span>
                </span>
                <span className="status">
                  <Tag compact>{d.notificationMessage ?? ''}</Tag>
                </span>
                <Link to={`/home/${d.id}`} className="details-link">
                  View Details
                  <Icon id="arrow-right-outline" size="sm" />
                </Link>
              </li>
            ))}
          </ul>
        ))}
    </section>
  );
}
