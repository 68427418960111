import * as React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Button, ScreenContent, ScreenHeader } from '@cae/cobalt-react';

import { useUserInfo } from '@/contexts/userInfo';
import { useHasPermission } from '@/shared/hooks/useHasPermission';
import { DataLoadingError, DataLoadingGrid, NoData } from '@/shared/components';

import { useDocumentTreeQuery } from '@/features/documents/api/useDocumentsQuery';
import { BreadcrumbsUpdated } from '@/features/documents/components/breadcrumbs/BreadcrumbsUpdated';
import { DocumentsUpdatedListing } from '@/features/documents/components/documents-updated-listing/DocumentsUpdatedListing';
import { DocumentElement } from '@/open-api/models/DocumentElement';
import { SearchDocumentsUpdatedInput } from '@/features/documents/components/search-documents-input/SearchDocumentsUpdatedInput';

function getPageHeading(organizationName: string | undefined): string {
  return organizationName
    ? `${organizationName} training documents`
    : 'Training documents';
}

export function CompanyDocumentsNewPage(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedOrganization } = useUserInfo();
  const [keyword, setKeyword] = React.useState<string>('');
  const [showResults, setShowResults] = React.useState<boolean>(false);
  const [currentFolder, setCurrentFolder] = React.useState<string | undefined>(
    location.state?.parentFolderId || undefined
  );
  const [filteredItems, setFilteredItems] = React.useState<
    Array<DocumentElement>
  >([]);
  const [parentFolders, setParentFolders] = React.useState<
    Array<DocumentElement>
  >([]);

  React.useEffect(() => {
    if (location.state?.parentFolderId) {
      setCurrentFolder(location.state?.parentFolderId);
    }
    setKeyword('');
  }, [location.state?.parentFolderId]);

  const {
    data: jsonData,
    isError,
    isInitialLoading,
    isSuccess,
  } = useDocumentTreeQuery({
    type: 'company',
    searchText: keyword || undefined,
    currentFolder,
  });

  const filterData = React.useCallback((): void => {
    if (keyword && jsonData) {
      const searchResults = jsonData.filter(item =>
        item.name?.toLowerCase().includes(keyword.toLowerCase())
      );
      setFilteredItems(searchResults);
      setShowResults(true);
    } else if (jsonData) {
      const folderItems = jsonData.filter(item =>
        currentFolder ? item.parentId === currentFolder : item.parentId === null
      );
      setFilteredItems(folderItems);
      setShowResults(false);
      const parents = [];
      let parentId = currentFolder;
      while (parentId) {
        const parent = jsonData.find(item => item.id === parentId);
        if (parent) {
          parents.unshift(parent);
          parentId = parent.parentId as string;
        } else {
          parentId = undefined;
        }
      }
      setParentFolders(parents);
    }
  }, [keyword, currentFolder, jsonData, setFilteredItems, setParentFolders]);
  const filterDataMemoized = React.useCallback(() => {
    filterData();
  }, [filterData]);

  React.useEffect(() => {
    if (isSuccess) {
      filterDataMemoized();
    }
  }, [currentFolder, keyword, isSuccess, filterDataMemoized]);

  const handleAddDocumentsClick: React.MouseEventHandler<
    HTMLButtonElement
  > = e => {
    e.preventDefault();
    navigate('/documents/company/submit');
  };

  const handleFolderClick = (folderId: string): void => {
    if (folderId === 'root') {
      setCurrentFolder(undefined);
    } else {
      setCurrentFolder(folderId);
    }
    setKeyword('');
    setShowResults(false);
  };

  const handleSearchFolderClick = (id: string | undefined): void => {
    setCurrentFolder(id);
  };

  const hasPermission = useHasPermission();

  return (
    <>
      <div className="page-wrapper">
        <ScreenHeader elevation="base">
          <hgroup>
            <h2>{getPageHeading(selectedOrganization?.name)}</h2>
          </hgroup>
          {hasPermission('document_upload') ? (
            <div className="main-cta">
              <Button
                data-testid="submitDocumentButton"
                type="button"
                variant="primary"
                size="md"
                onClick={handleAddDocumentsClick}
              >
                Submit document
              </Button>{' '}
            </div>
          ) : null}

          <div className="toolbar">
            <SearchDocumentsUpdatedInput
              jsonData={jsonData || []}
              keyword={keyword}
              setKeyword={setKeyword}
              setShowResults={setShowResults}
              handleFolderClick={handleSearchFolderClick}
            />
          </div>
        </ScreenHeader>
        {!showResults ? (
          <ScreenContent>
            {isInitialLoading && (
              <DataLoadingGrid numberRows={15} numberColumns={1} />
            )}
            {isError && <DataLoadingError />}
            {isSuccess && parentFolders?.length > 0 && (
              <BreadcrumbsUpdated
                parentFolders={parentFolders}
                onFolderClick={handleFolderClick}
              />
            )}
            {isSuccess && filteredItems?.length === 0 && <NoData />}
            {isSuccess && filteredItems?.length > 0 && (
              <DocumentsUpdatedListing
                data={filteredItems}
                onFolderClick={handleFolderClick}
              />
            )}
          </ScreenContent>
        ) : (
          <ScreenContent>
            <DocumentsUpdatedListing
              data={filteredItems}
              onFolderClick={handleFolderClick}
            />
          </ScreenContent>
        )}
      </div>
      <Outlet />
    </>
  );
}

CompanyDocumentsNewPage.displayName = 'CompanyDocumentsNewPage';
