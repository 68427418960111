import * as React from 'react';
import { Icon } from '@cae/cobalt-react';

import { useDoubleClick } from '@/shared/hooks/useDoubleClick';

import { DocumentDto } from '@/open-api';

interface DocumentElementUpdated {
  id: string;
  name: string;
  numberOfItemsInside: number;
}
export function DirectoryButton({
  data,
  onClick,
  onDoubleClick,
}: {
  data: DocumentDto | DocumentElementUpdated;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  onDoubleClick: React.MouseEventHandler<HTMLButtonElement>;
}): JSX.Element {
  const ref = React.useRef<React.ElementRef<'button'>>(null);

  useDoubleClick({
    ref,
    onClick,
    onDoubleClick,
  });

  return (
    <button
      type="button"
      className="name"
      data-type="directory"
      ref={ref}
      value={data.id}
    >
      <Icon id="folder-solid" size="md" />
      {data.name}
      <span className="count">
        {data?.numberOfItemsInside ? data?.numberOfItemsInside : 0} items
      </span>
    </button>
  );
}
