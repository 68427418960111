import { AllAwatingActions, WidgetService } from '@/open-api';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

function useGetAwaitingDataQuery(): UseQueryResult<AllAwatingActions, unknown> {
  return useQuery<AllAwatingActions>({
    queryKey: ['notifications awaiting info'],
    queryFn: () => WidgetService.getAllAwatingActions({}),
  });
}

export default useGetAwaitingDataQuery;
