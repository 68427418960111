import { useNavigate } from 'react-router-dom';
import { Icon } from '@cae/cobalt-react';

import { useHasPermission } from '@/shared/hooks/useHasPermission';
import { useDownloadFile } from '@/shared/hooks/useDownloadFile'; // Import the hook

import { RecordStatus } from '@/open-api';
import type { ReservationData } from '@/features/reservation-details/types';
import { toastMessages } from '@/shared/helpers/toastMessages';

const trainingRecordsIconMapping: { [k: string]: string } = {
  success: 'checkmark-circle-solid',
  warning: 'warning-triangle-solid',
  danger: 'warning-triangle-solid',
  neutral: 'remove-solid',
  'neutral-pending': 'remove-solid',
};

type TrainingRecordsStatusProps = {
  reservationId: string;
  data: ReservationData['statusInfo']['trainingRecordDetails'];
  schedulerReservationId?: string;
  documentId?: string;
};

export function TrainingRecordsStatus({
  reservationId,
  data,
  schedulerReservationId,
  documentId,
}: TrainingRecordsStatusProps): JSX.Element {
  const hasPermission = useHasPermission();
  const navigate = useNavigate();

  const { downloadFile } = useDownloadFile({
    onSuccess: () => {
      toastMessages.success('Export record successfully');
    },
    onError: () => {
      toastMessages.error('Export record failed');
    },
  });

  const handleViewClick: React.MouseEventHandler<
    HTMLButtonElement
  > = async e => {
    e.preventDefault();
    navigate(`/records/${reservationId}`);
  };

  const handleDownloadClick: React.MouseEventHandler<
    HTMLButtonElement
  > = async e => {
    e.preventDefault();
    if (documentId) {
      downloadFile({
        url: `/records/${documentId}/download`,
        fileName:
          schedulerReservationId ?? `Record_document_${reservationId}.pdf`,
      });
    }
  };

  return (
    <article>
      <h4>Training record</h4>
      {data && (
        <>
          <p>
            {data.status && (
              <Icon
                id={trainingRecordsIconMapping[data.style]}
                className={`status ${data.style}`}
                size="sm"
              />
            )}
            {data.message}
          </p>
          {hasPermission('reservations_confirmation_letter_actions') &&
            hasPermission('view_records_details') &&
            data.status === RecordStatus.AVAILABLE && (
              <div className="actions">
                <button type="button" onClick={handleViewClick}>
                  View
                </button>
                <button type="button" onClick={handleDownloadClick}>
                  <Icon id="download-solid" size="sm" />
                </button>
              </div>
            )}
        </>
      )}
    </article>
  );
}

TrainingRecordsStatus.displayName = 'TrainingRecordsStatus';
