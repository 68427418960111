import { useQuery, UseQueryResult } from '@tanstack/react-query';
import {
  DisciplineTypeDto,
  InstructorAvailabilityDto,
  InstructorManagementService,
} from '@/open-api';

export function useAvailableInstructorsQuery(
  program: string,
  trainingCenterId: string,
  discipline: DisciplineTypeDto | undefined
): UseQueryResult<InstructorAvailabilityDto, Error> {
  return useQuery({
    queryKey: [
      'instructor',
      'available-instructors',
      program,
      trainingCenterId,
      discipline,
    ],
    queryFn: () => {
      if (!program || !trainingCenterId || !discipline) {
        return Promise.reject(new Error('Missing required parameters'));
      }
      return InstructorManagementService.getAvailableInstructors({
        trainingCenterId,
        program,
        discipline,
      });
    },
  });
}
