import { useFlags } from 'launchdarkly-react-client-sdk';
import { CompanyDocumentsNewPage } from './CompanyDocumentsNewPage';
import { CompanyDocumentsPage } from './CompanyDocumentsPage';

function CompanyDocumentsPageWrapper(): JSX.Element {
  const { companydocumentsnewimplementation } = useFlags();
  return !companydocumentsnewimplementation ? (
    <CompanyDocumentsNewPage />
  ) : (
    <CompanyDocumentsPage />
  );
}

export default CompanyDocumentsPageWrapper;
