import { Link } from 'react-router-dom';
import { Card, Icon } from '@cae/cobalt-react';

import {
  type ReservationsTilesCount,
  useGetReservationsTilesCountQuery,
} from '@/features/reservations/api/useGetReservationsTilesCountQuery';

const categories = [
  {
    category: 'provisional',
    icon: 'warning-triangle-solid',
    label: 'Provisional reservations at risk of cancellation',
    style: 'danger',
  },
  {
    category: 'etca',
    icon: 'warning-circle-solid',
    label: 'Missing eTCA in upcoming reservations',
    style: 'warning',
  },
];

export function DashboardAlertCards(): JSX.Element {
  const { data, isSuccess } = useGetReservationsTilesCountQuery();

  if (!isSuccess) return <></>;

  const counts = data ? Object.values(data).reduce((a, b) => a + b, 0) : 0;

  return (
    <div className="dashboard--alert-cards" data-testid="alertCards">
      {counts < 1 && (
        <Card elevation="skim" className="no-alerts">
          <Icon
            className="status-icon success"
            id="checkmark-circle-solid"
            size="md"
          />
          <h3>You have no alerts</h3>
          <p>Looks like everything is taken care of and where it should be.</p>
        </Card>
      )}
      {counts > 0 &&
        categories.map(t => {
          const hasCount =
            data && data[t.category as keyof ReservationsTilesCount] > 0;
          return hasCount ? (
            <Link
              data-testid={`link-card-${t.category}`}
              to={`alerts/${t.category}`}
              key={`link-${t.category}`}
            >
              <Card elevation="skim">
                <h3>{data[t.category as keyof ReservationsTilesCount]}</h3>
                <Icon id={t.icon} className={`status-icon ${t.style}`} />
                <p>{t.label}</p>
                <Icon
                  id="arrow-right-outline"
                  size="sm"
                  className="link-icon"
                />
              </Card>
            </Link>
          ) : null;
        })}
    </div>
  );
}

DashboardAlertCards.displayName = 'DashboardAlertCards';
