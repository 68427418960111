import { useGetProfileQuery } from '@/shared/api/useGetProfileQuery';

import './DashboardGreeting.css';
interface DashboardGreetingProps {
  readonly isVisitor: boolean;
}
export function DashboardGreeting({
  isVisitor,
}: DashboardGreetingProps): JSX.Element {
  const { data } = useGetProfileQuery();

  const currentHour = new Date().getHours();

  let welcomeMessage = '';
  if (currentHour >= 5 && currentHour < 12) {
    welcomeMessage = 'Good Morning';
  } else if (currentHour >= 12 && currentHour < 17) {
    welcomeMessage = 'Good Afternoon';
  } else {
    welcomeMessage = 'Good Evening';
  }

  const firstName = data?.firstName ? `, ${data.firstName}` : '';
  const introMessage = (isVisitor: boolean): string => {
    if (isVisitor)
      return 'Welcome to CAE Connect, your partner in your training journey.';
    return 'All of your tools in a single place, enabled by the power of CAE.';
  };
  return (
    <hgroup>
      <h2>
        {welcomeMessage}
        {firstName}
      </h2>
      <p>{introMessage(isVisitor)}</p>
    </hgroup>
  );
}

DashboardGreeting.displayName = 'DashboardGreeting';
