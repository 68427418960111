/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AllAlerts } from '../models/AllAlerts';
import type { AllAwatingActions } from '../models/AllAwatingActions';
import type { PriorityResponse } from '../models/PriorityResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class WidgetService {
  /**
   * @returns PriorityResponse OK
   * @throws ApiError
   */
  public static getPriorities(): CancelablePromise<Array<PriorityResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/widget/priority',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns AllAwatingActions OK
   * @throws ApiError
   */
  public static getAllAwatingActions({
    organizationId,
  }: {
    organizationId?: string;
  }): CancelablePromise<AllAwatingActions> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/widget/awaiting-actions',
      headers: {
        'Organization-Id': organizationId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns AllAlerts OK
   * @throws ApiError
   */
  public static getAllAlerts({
    organizationId,
  }: {
    organizationId?: string;
  }): CancelablePromise<AllAlerts> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/widget/alert-cards',
      headers: {
        'Organization-Id': organizationId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
}
