import { useInstructorsListQuery } from '@/features/instructor-management/api/useInstructorsListQuery.ts';
import { DataLoadingError, DataLoadingGrid, NoData } from '@/shared/components';
import { Card, Avatar, Icon, Tooltip, Modal, Button } from '@cae/cobalt-react';
import './InstructosListContainer.css';
import {
  DisciplineTypeDto,
  InstructorDetailsByProgram,
  InstructorTypeDetailsDto,
} from '@/open-api';
import { useEffect, useState } from 'react';
import {
  ActionModal,
  ActionOverflowMenu,
  ReportsForm,
} from '@/features/instructor-management';
import {
  getNameInitials,
  getStatusIcon,
  statusText,
} from '@/features/instructor-management/shared/Utils.tsx';
import { DownloadReportAction } from '@/features/instructor-management/components/download-reports-action/DownloadReportAction.tsx';
import { useViewportSize } from '@/shared/hooks/useViewportSize.ts';
import BatchInstructorsModal from '@/features/instructor-management/components/batch-instructors-modal/BatchInstructorsModal.tsx';
import InstructorDetails from '@/features/instructor-management/components/instructor-detials/InstructorDetails.tsx';

interface InstructorsListContainerProps {
  trainingCenterId: string;
  program: string;
  location: string;
  setIsWarning: React.Dispatch<React.SetStateAction<boolean>>;
}

const getProgressSection = (
  title: string,
  instructorDetails: InstructorTypeDetailsDto,
  handleAddInstructor: () => void
): JSX.Element => {
  return (
    <div className="progress-section">
      <span className="progress-title">{title}</span>
      <div className="progress-info">
        <span className="progress-count">
          {instructorDetails?.numberOfApprovedInstructors ?? 0}/
          {instructorDetails?.numberOfAuthorizedInstructors ?? 0}
        </span>
        {instructorDetails?.alert?.type === 'TOO_FEW_CHECK_AIRMEN' && (
          <Icon id="warning-triangle-solid" />
        )}
        <Button
          variant="ghost"
          className="add-instructors"
          onClick={handleAddInstructor}
        >
          Add instructors
        </Button>
      </div>
    </div>
  );
};

export function InstructorsListContainer({
  trainingCenterId,
  program,
  location,
  setIsWarning,
}: Readonly<InstructorsListContainerProps>): JSX.Element {
  const { refetch, data, isError, isSuccess, isLoading, isFetching } =
    useInstructorsListQuery(program, trainingCenterId);

  const { instructorProgram = {}, instructors = [] } = data || {};
  const {
    groundSchoolDetails = {},
    simulatorDetails = {},
    checkAirmanDetails = {},
  } = instructorProgram;
  const [modalOpen, setModalOpen] = useState(false);
  const [action, setAction] = useState('');
  const [selectedInstructor, setSelectedInstructor] =
    useState<InstructorDetailsByProgram>({});
  const [reportOpen, setReportOpen] = useState(false);
  const [batchInstructorsModalOpen, setBatchInstructorsModalOpen] =
    useState(false);
  const [instructorDetailsOpen, setInstructorDetailsOpen] = useState(false);
  const [batchInstructorsDiscipline, setBatchInstructorsDiscipline] = useState<
    DisciplineTypeDto | undefined
  >();
  const toggleReportsModal = (): void => setReportOpen(!reportOpen);
  const { width } = useViewportSize();

  const handleAddInstructor = (discipline: DisciplineTypeDto): void => {
    setBatchInstructorsDiscipline(discipline);
    setBatchInstructorsModalOpen(true);
  };

  const handleMenuBtnClick = (
    action: string,
    instructor: InstructorDetailsByProgram
  ): void => {
    setAction(action);
    setSelectedInstructor(instructor);
    setModalOpen(true);
  };

  const handleAction = (action: string): void => {
    setAction(action);
    setModalOpen(true);
  };

  const handleInstructorCard = (
    instructor: InstructorDetailsByProgram
  ): void => {
    setSelectedInstructor(instructor);
    setInstructorDetailsOpen(true);
  };
  useEffect(() => {
    setIsWarning(
      checkAirmanDetails?.alert?.type === 'TOO_FEW_CHECK_AIRMEN' || false
    );
  }, [checkAirmanDetails, setIsWarning]);

  useEffect(() => {
    if (!modalOpen && !batchInstructorsModalOpen) {
      refetch();
    }
  }, [modalOpen, batchInstructorsModalOpen, refetch]);

  if (isLoading || isFetching) {
    return (
      <DataLoadingGrid
        numberColumns={10}
        numberRows={10}
        style={{ marginTop: '1rem' }}
      />
    );
  }

  if (isError) {
    return <DataLoadingError />;
  }

  if (isSuccess && !data?.instructors?.length) {
    return <NoData />;
  }

  return (
    <>
      <div className={'instructors-list'}>
        <div className="instructors-list__header">
          <div className="instructors-progress-container">
            {getProgressSection('Ground school', groundSchoolDetails, () =>
              handleAddInstructor(DisciplineTypeDto.GROUND_SCHOOL)
            )}
            {getProgressSection('Simulator training', simulatorDetails, () =>
              handleAddInstructor(DisciplineTypeDto.SIMULATOR)
            )}
            {getProgressSection('Checking', checkAirmanDetails, () =>
              handleAddInstructor(DisciplineTypeDto.CHECK_AIRMAN)
            )}
          </div>
          <DownloadReportAction
            permitted={true}
            label={'Download reports'}
            onDownloadReportClick={toggleReportsModal}
          />
        </div>
        <div className="instructor-management-list">
          <h4>{location} Instructors</h4>
          <div className="card-list">
            {instructors.map(instructor => (
              <Card
                key={instructor.instructorId}
                elevation="raised"
                orientation="vertical"
                variant="primary"
                className="card"
                onClick={() => handleInstructorCard(instructor)}
              >
                <div className="card-content">
                  <Avatar
                    as="span"
                    elevation="base"
                    label={instructor.instructorName}
                    initials={getNameInitials(instructor?.instructorName ?? '')}
                    size="sm"
                    variant="round"
                    className="avatar"
                  />
                  <div className="info-section">
                    <div className="instructor-name">
                      <h3>
                        {instructor.instructorName}
                        {instructor.isNewlyApprovedInstructor && (
                          <span className="new-instructor-badge">
                            New instructor
                          </span>
                        )}
                      </h3>
                    </div>
                    <ul className="details-list">
                      <li className="icon-text-alignment">
                        <Tooltip
                          offsetOptions={7}
                          placement="bottom"
                          highContrast
                        >
                          <Tooltip.Trigger>
                            <span>
                              {getStatusIcon(
                                instructor.groundSchoolApprovalState ?? ''
                              )}
                            </span>
                          </Tooltip.Trigger>
                          <Tooltip.Content>
                            {instructor?.groundSchoolApprovalState
                              ? statusText[
                                  instructor.groundSchoolApprovalState ||
                                    'default'
                                ]
                              : ''}
                          </Tooltip.Content>
                        </Tooltip>
                        <span className="text">Ground School</span>
                      </li>
                      <li className="icon-text-alignment">
                        <Tooltip
                          offsetOptions={7}
                          placement="bottom"
                          highContrast
                        >
                          <Tooltip.Trigger>
                            <span>
                              {getStatusIcon(
                                instructor.simulatorApprovalState ?? ''
                              )}
                            </span>
                          </Tooltip.Trigger>
                          <Tooltip.Content>
                            {instructor?.simulatorApprovalState
                              ? statusText[
                                  instructor.simulatorApprovalState || 'default'
                                ]
                              : ''}
                          </Tooltip.Content>
                        </Tooltip>
                        <span className="text">Simulator training</span>
                      </li>
                      <li className="icon-text-alignment">
                        <Tooltip
                          offsetOptions={7}
                          placement="bottom"
                          highContrast
                        >
                          <Tooltip.Trigger>
                            <span>
                              {getStatusIcon(
                                instructor.checkingApprovalState ?? ''
                              )}
                            </span>
                          </Tooltip.Trigger>
                          <Tooltip.Content>
                            {instructor?.checkingApprovalState
                              ? statusText[
                                  instructor.checkingApprovalState || 'default'
                                ]
                              : ''}
                          </Tooltip.Content>
                        </Tooltip>
                        <span className="text">Checking</span>
                      </li>
                      <li className="icon-text-alignment">
                        <ActionOverflowMenu
                          onBtnClick={handleMenuBtnClick}
                          instructor={instructor}
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </div>
      <ActionModal
        modalOpen={modalOpen}
        toggleActionModal={() => setModalOpen(false)}
        action={action}
        selectedInstructor={selectedInstructor}
        program={program}
        location={location}
        trainingCenterId={trainingCenterId}
      />
      <Modal
        open={reportOpen}
        onCancel={toggleReportsModal}
        placement={width < 480 ? 'bottom' : 'right'}
        dismissible
        size="sm"
        className="instructor-management--dialog"
      >
        {reportOpen && (
          <ReportsForm
            onCancel={toggleReportsModal}
            selectedLocation={location}
            program={program}
          />
        )}
      </Modal>
      <BatchInstructorsModal
        modalOpen={batchInstructorsModalOpen}
        toggleModal={() =>
          setBatchInstructorsModalOpen(!batchInstructorsModalOpen)
        }
        trainingCenterId={trainingCenterId}
        program={program}
        discipline={batchInstructorsDiscipline}
      />
      <InstructorDetails
        instructorId={selectedInstructor.instructorId ?? ''}
        instructorName={selectedInstructor.instructorName ?? ''}
        checkingApprovalState={selectedInstructor.checkingApprovalState ?? ''}
        simulatorApprovalState={selectedInstructor.simulatorApprovalState ?? ''}
        groundSchoolApprovalState={
          selectedInstructor.groundSchoolApprovalState ?? ''
        }
        trainingCenterName={location}
        programName={program}
        instructorDetailsOpen={instructorDetailsOpen}
        toggleInstructorDetails={() =>
          setInstructorDetailsOpen(!instructorDetailsOpen)
        }
        onBtnClick={handleAction}
      />
    </>
  );
}
