import { useFlags } from 'launchdarkly-react-client-sdk';
import { CAEDocumentsPage } from './CAEDocumentsPage';
import { CAEDocumentsNewPage } from './CAEDocumentsNewPage';

function CAEDocumentsPageWrapper(): JSX.Element {
  const { caedocumentsnewimplementation } = useFlags();
  return !caedocumentsnewimplementation ? (
    <CAEDocumentsNewPage />
  ) : (
    <CAEDocumentsPage />
  );
}

export default CAEDocumentsPageWrapper;
