import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Card, CardHeader, Icon } from '@cae/cobalt-react';
import './EtcaAlertDetails.css';

interface Alert {
  reservationId: string;
  courseName: string;
  clientName: string;
  startDate: string;
}

const EtcaAlertDetails: React.FC = () => {
  const location = useLocation();
  const alertData =
    (location.state as { alertData?: Alert[] })?.alertData || [];

  const getDescription = (numberOfAlerts: number): string => {
    return `You have ${numberOfAlerts} reservations missing eTCAs with training starting in the next 21 days.`;
  };

  const renderMessage = (dateOfTraining: string): string => {
    const today = new Date();
    const diffInMs = Date.parse(dateOfTraining) - today.getTime();
    const diffInDays = Math.ceil(diffInMs / (1000 * 3600 * 24));

    return diffInDays >= 0
      ? `Training starts in ${diffInDays} days.`
      : 'Training is already overdue.';
  };

  return (
    <aside className="reservations--alert-details">
      {alertData.length === 0 ? (
        <p>No eTCA alerts available.</p>
      ) : (
        <>
          <h3>Your eTCA Alerts</h3>
          <p>{getDescription(alertData.length)}</p>
          <div className="scroll-container">
            {alertData.map((alert: Alert) => (
              <Link
                to={`/reservations/${alert.reservationId}`}
                key={alert.reservationId}
              >
                <Card key={alert.reservationId}>
                  <CardHeader>
                    <hgroup>
                      <h5>{alert.courseName}</h5>
                      <h4>{alert.clientName}</h4>
                    </hgroup>
                    <Icon
                      size="sm"
                      color="var(--danger-color)"
                      id="circle-solid"
                    />
                  </CardHeader>
                  <p>{renderMessage(alert.startDate)}</p>
                </Card>
              </Link>
            ))}
          </div>
        </>
      )}
    </aside>
  );
};

EtcaAlertDetails.displayName = 'EtcaAlertDetails';

export default EtcaAlertDetails;
