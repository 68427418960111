import FinanceLandingPage from '@/features/finance/components/FinanceLandingPage';
import { InvoiceAndPaymentsPage } from '../invoice-payments/InvoiceAndPaymentsPage';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useUserInfo } from '@/contexts/userInfo';

function FinancePage(): JSX.Element {
  const { invoiceandpaymentwebapp } = useFlags();
  const { invoiceAndPaymentInternalUserWebApp } = useFlags();
  const { isInternalUser } = useUserInfo();
  if (
    invoiceandpaymentwebapp ||
    (invoiceAndPaymentInternalUserWebApp && isInternalUser)
  ) {
    return <InvoiceAndPaymentsPage />;
  } else {
    return <FinanceLandingPage />;
  }
}

export default FinancePage;
