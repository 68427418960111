import { Card, CardFooter, Icon, Button } from '@cae/cobalt-react';
import './InstructorAlertCards.css';
import { useInstructorAlertQuery } from '@/features/instructor-management/api/useInstructorAlertQuery.ts';
import {
  AlertTypeDto,
  DisciplineTypeDto,
  InstructorProgramAlertDto,
} from '@/open-api';
import { useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface InstructorAlertCardsProps {
  setBatchInstructorsData: (data: InstructorProgramAlertDto) => void;
  setBatchInstructorsModalOpen: (open: boolean) => void;
}
export function InstructorAlertCards({
  setBatchInstructorsData,
  setBatchInstructorsModalOpen,
}: Readonly<InstructorAlertCardsProps>): JSX.Element {
  const { canViewAlertBelowCertainThreshold } = useFlags();
  const { data = [] } = useInstructorAlertQuery();
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const handleMouseDown: React.MouseEventHandler = (
    e: React.MouseEvent<HTMLDivElement>
  ): void => {
    setIsDragging(true);
    setStartX(e.pageX - e.currentTarget.offsetLeft);
    setScrollLeft(e.currentTarget.scrollLeft);
  };

  const filterData = data.filter(item => {
    return (
      canViewAlertBelowCertainThreshold &&
      item?.payload?.discipline === DisciplineTypeDto.CHECK_AIRMAN &&
      !!item?.message
    );
  });

  const handleMouseMove: React.MouseEventHandler = (
    e: React.MouseEvent<HTMLDivElement>
  ): void => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - e.currentTarget.offsetLeft;
    const walk = (x - startX) * 2;
    e.currentTarget.scrollLeft = scrollLeft - walk;
  };

  const handleAdd = (data: InstructorProgramAlertDto): void => {
    setBatchInstructorsData(data);
    setBatchInstructorsModalOpen(true);
  };

  return (
    <div
      role={'tablist'}
      tabIndex={0}
      data-testid="card-list"
      className="card-list card-container"
      onMouseDown={handleMouseDown}
      onMouseLeave={() => {
        setIsDragging(false);
      }}
      onMouseUp={() => {
        setIsDragging(false);
      }}
      onMouseMove={handleMouseMove}
    >
      {filterData.map((alert, index) => (
        <Card
          role={'menuitem'}
          data-testid={`instructor-card`}
          key={alert?.message ?? '' + index}
        >
          <p>{alert.message}</p>
          <CardFooter>
            {(alert?.type === AlertTypeDto.TOO_FEW_CHECK_AIRMEN ||
              alert?.type === AlertTypeDto.NEW_INSTRUCTOR) && (
              <Button
                type="button"
                variant="ghost"
                onClick={() => handleAdd(alert)}
              >
                Add new
                <Icon
                  id="arrow-right-outline"
                  size="sm"
                  className="link-icon"
                />
              </Button>
            )}
          </CardFooter>
        </Card>
      ))}
    </div>
  );
}
