/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckinUserRequest } from '../models/CheckinUserRequest';
import type { PagedListOfUserResponse } from '../models/PagedListOfUserResponse';
import type { SelectedOrganizationUpdateRequest } from '../models/SelectedOrganizationUpdateRequest';
import type { UserAccessResponse } from '../models/UserAccessResponse';
import type { UserBulkResponse } from '../models/UserBulkResponse';
import type { UserCountResponse } from '../models/UserCountResponse';
import type { UserInformationChangeRequest } from '../models/UserInformationChangeRequest';
import type { UserOrganization } from '../models/UserOrganization';
import type { UserOrganizationRoleMapping } from '../models/UserOrganizationRoleMapping';
import type { UserProfileRequest } from '../models/UserProfileRequest';
import type { UserRequest } from '../models/UserRequest';
import type { UserResponse } from '../models/UserResponse';
import type { UsersMetadata } from '../models/UsersMetadata';
import type { UserSyncRequest } from '../models/UserSyncRequest';
import type { UserSyncResponse } from '../models/UserSyncResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UserManagementService {
  /**
   * @returns UserResponse OK
   * @throws ApiError
   */
  public static getUserById({
    id,
  }: {
    id: string;
  }): CancelablePromise<UserResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/{id}',
      path: {
        id: id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static updateUser({
    id,
    requestBody,
  }: {
    id: string;
    requestBody: UserRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/users/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static deleteUser({ id }: { id: string }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/users/{id}',
      path: {
        id: id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static updateProfile({
    id,
    requestBody,
  }: {
    id: string;
    requestBody: UserProfileRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/users/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static updateUserWelcome({
    id,
    requestBody,
  }: {
    id: string;
    requestBody: UserRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/users/{id}/welcome',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static syncUserWithExternalService({
    id,
  }: {
    id: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/users/{id}/syncUserWithExternalService',
      path: {
        id: id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns PagedListOfUserResponse OK
   * @throws ApiError
   */
  public static getUsers({
    pageIndex,
    pageSize = 10,
    sort,
    status = 'all',
    role,
    searchText,
    organizationId,
  }: {
    /**
     * Page index
     */
    pageIndex?: number;
    /**
     * Page size
     */
    pageSize?: number;
    /**
     * Sort attributes
     */
    sort?: Array<
      | '+firstName'
      | '+email'
      | '+lastLoggedInTime'
      | '+updatedDate'
      | '+roleNames'
      | '-firstName'
      | '-email'
      | '-lastLoggedInTime'
      | '-updatedDate'
      | '-roleNames'
    >;
    /**
     * Status of the user
     */
    status?: 'all' | 'active' | 'inactive';
    /**
     * User role
     */
    role?: Array<'Admin' | 'Training' | 'Records' | 'Finance'>;
    /**
     * Search term for additional filtering on user name or email
     */
    searchText?: string;
    organizationId?: string;
  }): CancelablePromise<PagedListOfUserResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users',
      headers: {
        'Organization-Id': organizationId,
      },
      query: {
        pageIndex: pageIndex,
        pageSize: pageSize,
        sort: sort,
        status: status,
        role: role,
        searchText: searchText,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns UserResponse Created
   * @throws ApiError
   */
  public static createUser({
    requestBody,
  }: {
    requestBody: UserRequest;
  }): CancelablePromise<UserResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static removeOrganizationsByUserId({
    id,
  }: {
    id: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users/{id}/organizations/remove',
      path: {
        id: id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static addOrganizationsByUserId({
    id,
    requestBody,
  }: {
    id: string;
    requestBody: UserOrganizationRoleMapping;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users/{id}/organizations/add',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static sendUserChangeRequest({
    id,
    requestBody,
  }: {
    id: string;
    requestBody: UserInformationChangeRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users/{id}/internalUserChangeRequest',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static sendExternalUserRequest({
    id,
    requestBody,
  }: {
    id: string;
    requestBody: UserInformationChangeRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users/{id}/externalUserChangeRequest',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns UserBulkResponse OK
   * @throws ApiError
   */
  public static uploadCsvFile({
    requestBody,
  }: {
    requestBody?: {
      file: Blob;
    };
  }): CancelablePromise<Array<UserBulkResponse>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users/upload',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns UserSyncResponse OK
   * @throws ApiError
   */
  public static syncUser({
    requestBody,
  }: {
    requestBody: UserSyncRequest;
  }): CancelablePromise<UserSyncResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users/sync',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static onboardOrgsForInternalUser(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users/me/onboard',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns UserResponse Created
   * @throws ApiError
   */
  public static enrollPublicVisitors({
    requestBody,
  }: {
    requestBody: CheckinUserRequest;
  }): CancelablePromise<UserResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users/enroll_public_visitors',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static updateSelectedOrganization({
    requestBody,
  }: {
    requestBody: SelectedOrganizationUpdateRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/users/me/selectedOrganization',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static getOrganizationsByUserId({
    id,
  }: {
    id: string;
  }): CancelablePromise<Array<Record<string, Record<string, any>>>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/{id}/organizations',
      path: {
        id: id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns UserResponse OK
   * @throws ApiError
   */
  public static getUserDiscrepancies({
    id,
  }: {
    id: string;
  }): CancelablePromise<UserResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/{id}/discrepancies',
      path: {
        id: id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns UsersMetadata OK
   * @throws ApiError
   */
  public static getUsersMetadata({
    role,
    searchText,
    organizationId,
  }: {
    /**
     * User role
     */
    role?: Array<'Admin' | 'Training' | 'Records' | 'Finance'>;
    /**
     * Search term for additional filtering on user name or email
     */
    searchText?: string;
    organizationId?: string;
  }): CancelablePromise<UsersMetadata> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/meta',
      headers: {
        'Organization-Id': organizationId,
      },
      query: {
        role: role,
        searchText: searchText,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns UserAccessResponse OK
   * @throws ApiError
   */
  public static getLoggedInUserInfo(): CancelablePromise<UserAccessResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/me',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns UserOrganization OK
   * @throws ApiError
   */
  public static getUserOrganization(): CancelablePromise<
    Array<UserOrganization>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/me/organizations',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns UserCountResponse OK
   * @throws ApiError
   */
  public static getUsersCountDeprecated(): CancelablePromise<UserCountResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/count',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
}
