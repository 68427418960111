import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Card, CardHeader } from '@cae/cobalt-react';
import './ProvisionalAlertDetails.css';

interface Alert {
  reservationId: string;
  courseName: string;
  clientName: string;
  startDate: string;
}

const ProvisionalAlertDetails: React.FC = () => {
  const location = useLocation();
  const alertData =
    (location.state as { alertData?: Alert[] })?.alertData || [];

  const getDescription = (numberOfAlerts: number): string => {
    return `You have ${numberOfAlerts} provisional reservations at risk of cancellation`;
  };

  const renderMessage = (dateOfTraining: string): string => {
    const today = new Date();
    const diffInMs = Date.parse(dateOfTraining) - today.getTime();
    const diffInDays = Math.ceil(diffInMs / (1000 * 3600 * 24));

    return diffInDays >= 0
      ? `Training starts in ${diffInDays} days.`
      : 'Training is already overdue.';
  };

  return (
    <aside className="reservations--alert-details">
      {alertData.length === 0 ? (
        <p>No Provisional reservations at risk of cancellation.</p>
      ) : (
        <>
          <h3>Your Provisional reservations</h3>
          <p>{getDescription(alertData.length)}</p>
          <div className="scroll-container">
            {alertData.map((alert: Alert) => (
              <Link
                to={`/reservations/${alert.reservationId}`}
                key={alert.reservationId}
              >
                <Card key={alert.reservationId}>
                  <CardHeader>
                    <hgroup>
                      <h5>{alert.courseName}</h5>
                      <h4>{alert.clientName}</h4>
                    </hgroup>
                    <svg height="12" width="12" className="alert-danger-circle">
                      <circle cx="6" cy="6" r="6" fill="var(--danger-color)" />
                    </svg>
                  </CardHeader>
                  <p>{renderMessage(alert.startDate)}</p>
                </Card>
              </Link>
            ))}
          </div>
        </>
      )}
    </aside>
  );
};

ProvisionalAlertDetails.displayName = 'ProvisionalAlertDetails';

export default ProvisionalAlertDetails;
