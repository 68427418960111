import {
  DocumentElement,
  DocumentsManagementService,
  ListOfDocuments,
} from '@/open-api';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { SubmittedDocumentsFilters } from '@/features/documents/components/submitted-documents/filter-modal/useFilterModalReducer';
import useDebounce from '@/shared/hooks/useDebounce';

type DocumentsQuery = {
  type: 'cae' | 'company' | 'submitted';
  currentFolder?: string;
  filters?: SubmittedDocumentsFilters;
  searchText?: string;
  organizationId?: string;
};

const DEFAULT_INDEX = 0;
const DEFAULT_PAGE_SIZE = 1000;

export function useDocumentsQuery({
  type,
  currentFolder,
  filters,
  searchText,
}: DocumentsQuery): UseQueryResult<ListOfDocuments, unknown> {
  const { status } = filters || {};

  return useQuery({
    queryKey: ['documents', type, currentFolder, filters, searchText],
    queryFn: async () =>
      DocumentsManagementService.listDocuments({
        type,
        pageIndex: DEFAULT_INDEX,
        pageSize: DEFAULT_PAGE_SIZE,
        folderId: currentFolder,
        status,
        searchText,
      }),
  });
}

export function useDocumentTreeQuery({
  type,
  searchText = '',
  organizationId,
}: DocumentsQuery): UseQueryResult<DocumentElement[], unknown> {
  const debouncedValue = useDebounce(searchText, 500);
  return useQuery({
    queryKey: ['documentTree', type, searchText, organizationId],
    queryFn: async () =>
      DocumentsManagementService.getDocumentTree({
        type,
        searchText: debouncedValue,
        organizationId,
      }),
  });
}
