import './EditUserForm.css';
import { Toggle, CheckboxGroup, Button, Tag } from '@cae/cobalt-react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { RoleValues, UserRequest } from '../edit-user/editUserTypes';
import { EditChangeRequestForm } from '../edit-change-request-form/EditChangeRequestForm';
import { UserResponse } from '@/open-api';
import { externalRoleOptions } from '../edit-user/editUserConstants';
export interface OrganizationRoleMappingList {
  organizationId: string;
  roleId: null;
  roleIds: string[];
  welcomePage: boolean;
}

export interface RoleOptions {
  helperText: string;
  label: string;
  value: string;
  disabled: boolean;
}

export interface DefaultValues {
  active: boolean;
  roles: (string | undefined)[];
}

interface Props {
  readonly userDetails: UserResponse;
  readonly currentUserId: string | undefined;
  readonly rolesList: RoleOptions[];
  readonly setRolesList: React.Dispatch<React.SetStateAction<RoleOptions[]>>;
  readonly defaultValues: DefaultValues;
  readonly onSubmit: (data: UserRequest) => Promise<void>;
  readonly handleCancelEditUser: React.MouseEventHandler<HTMLButtonElement>;
}

const editUserSchema = z.object({
  active: z.boolean().default(true),
  roles: z.array(z.string()).nonempty('Roles is required'),
});

export function EditUserForm({
  userDetails,
  currentUserId,
  rolesList,
  setRolesList,
  defaultValues,
  onSubmit,
  handleCancelEditUser,
}: Props): JSX.Element {
  const externalRoleIds = externalRoleOptions.map(role => role.value);
  const { control, formState, handleSubmit } = useForm<UserRequest>({
    mode: 'onChange',
    resolver: zodResolver(editUserSchema),
    defaultValues,
  });
  const { isValid, isDirty } = formState;
  const handleConfirm = (): void => {
    handleSubmit(onSubmit)();
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="edit-user-form__form-container"
    >
      <div>
        <p>Name</p>
        <div className="edit-user-form__basic-info">
          <p>
            {userDetails?.firstName} {userDetails?.lastName}
          </p>
          {userDetails.active ? (
            <Tag color="green" compact>
              Active
            </Tag>
          ) : (
            <Tag color="red" compact>
              Inactive
            </Tag>
          )}
        </div>
      </div>

      <div>
        <p>Email</p>
        <div className="edit-user-form__basic-info">
          <p>{userDetails.email}</p>
        </div>
      </div>

      <Controller
        name="active"
        control={control}
        defaultValue={userDetails?.active}
        render={({
          field,
          fieldState: { invalid, isTouched, isDirty, error },
        }) => (
          <Toggle
            field={{
              ...field,
              defaultChecked: userDetails?.active,
              value: userDetails?.active ? 'true' : 'false',
              disabled: currentUserId === userDetails?.id,
            }}
            className={`create-user-toggle ${
              currentUserId === userDetails?.id
                ? 'edit-user-form__disable-user-status-toggle'
                : ''
            }`}
            meta={{
              dirty: isDirty,
              error: error?.message ?? '',
              touched: isTouched,
              valid: !invalid,
            }}
            label="User Status"
            orientation="vertical"
          />
        )}
      />

      <Controller
        name="roles"
        control={control}
        render={({ field }) => (
          <CheckboxGroup
            className="roles-checkbox-group"
            options={rolesList}
            orientation="vertical"
            label="Role"
            field={{
              ...field,
              name: 'roles',
              disabled: currentUserId === userDetails?.id,
              onChange: e => {
                const { value, checked } = e.target;
                let nextRolesValue: RoleValues = [...(field.value ?? [])];
                let nextRolesList: RoleOptions[] = [...rolesList];
                if (
                  rolesList.some(
                    role => role.value === value && role.label === 'Admin'
                  )
                ) {
                  if (checked) {
                    nextRolesValue = rolesList
                      .filter(role => role.label === 'Admin')
                      .map(role => role.value);
                  } else {
                    nextRolesValue = defaultValues.roles
                      .filter(
                        (roleId): roleId is string => roleId !== undefined
                      )
                      .filter(roleId => !externalRoleIds.includes(roleId));
                  }
                  nextRolesList = nextRolesList.map(role => ({
                    ...role,
                    disabled: role.label !== 'Admin' && checked,
                  }));
                } else {
                  nextRolesValue = checked
                    ? [...nextRolesValue, value]
                    : nextRolesValue.filter(role => role !== value);
                }
                setRolesList(nextRolesList);
                field.onChange(nextRolesValue);
              },
            }}
          />
        )}
      />
      <EditChangeRequestForm />
      <div className="edit-user-form__create-user-footer">
        <Button
          type="button"
          variant="secondary"
          className="primary-btn-color"
          onClick={handleCancelEditUser}
        >
          Cancel
        </Button>
        <Button
          type="button"
          variant="primary"
          disabled={!isValid || !isDirty}
          onClick={handleConfirm}
        >
          Save
        </Button>
      </div>
    </form>
  );
}
