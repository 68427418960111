import { OrganizationProfileUpdateRequest } from '@/open-api';
import { z } from 'zod';

export const PhoneFormSchema = z.object({
  phoneNumber: z.string().regex(/^\+\d{1,3}(?:\.\d{2})?\d{4,14}$/, {
    message: 'Please enter a valid phone number',
  }),
});

export const FaxFormSchema = z.object({
  faxNumber: z.string().regex(/^\+\d{1,3}(?:\.\d{2})?\d{4,14}$/, {
    message: 'Please enter a valid fax number',
  }),
});

export const AuthoritiesFormSchema = z.object({
  authorities: z.string().array().min(1, 'Select at least one authority'),
  certCode: z.string().max(10, 'At most 10 characters'),
});

export const OperatingRegionSchema = z.object({
  operatingRegion: z.string().min(1),
});

export const companyFleetSchema = z.object({
  vehicleTypeId: z.string().min(1),
  numberOfAircraft: z.string().min(0, { message: 'Aircrafts is required' }),
  tailNumbers: z.array(
    z.object({
      id: z.string(),
      tailNumber: z.string().min(0),
    })
  ),
});

export interface FormValues extends OrganizationProfileUpdateRequest {}
