import { ReservationDetailsResponse } from '@/open-api';

import { useSelectStatusInfo } from '../../api/hooks';

import { ConfirmationEmailStatusActions } from './ConfirmationEmailStatusActions';
import { ETCAStatusActions } from './ETCAStatusActions';
import { TSAStatusInfo } from './TSAStatus';
import { TrainingRecordsStatus } from './TrainingRecordsStatus';

import './StatusActionsFragment.css';

export function StatusActionsFragment({
  reservationId,
  schedulerReservationId,
  documentId,
}: {
  reservationId: string;
  schedulerReservationId?: string;
  documentId?: string;
}): null | JSX.Element {
  const { data } = useSelectStatusInfo(reservationId);
  const s = ReservationDetailsResponse.status;

  const confirmedInProgressCompleted =
    data &&
    data.status &&
    [s.CONFIRMED, s.IN_PROGRESS, s.COMPLETED].indexOf(data.status) >= 0;

  return !data?.status || !confirmedInProgressCompleted ? null : (
    <section className="dialog-details__fragment status-summary">
      <h3>Status Summary</h3>
      <ConfirmationEmailStatusActions
        data={data.confirmationEmail}
        reservationId={reservationId}
      />
      <ETCAStatusActions data={data.etca} reservationId={reservationId} />
      <TSAStatusInfo data={data.tsa} />
      <TrainingRecordsStatus
        reservationId={reservationId}
        data={data.trainingRecordDetails}
        schedulerReservationId={schedulerReservationId}
        documentId={documentId}
      />
    </section>
  );
}
