import { Modal, Button, AlertBanner } from '@cae/cobalt-react';
import './ApprovalLetterUploadModal.css';
import { FileUpload } from '@/shared/components';
import * as React from 'react';
import { useUploadApprovalLetterQuery } from '@/features/instructor-management/api/useUploadApprovalLetterQuery.ts';
import { useCallback } from 'react';

interface ApprovalLetterUploadModalProps {
  isUploadModalOpen: boolean;
  toggleUploadModal: (requestId?: string) => void;
  instructorName: string;
  requestId: string;
  formKey: string;
}

const ApprovalLetterUploadModal = ({
  isUploadModalOpen,
  toggleUploadModal,
  instructorName,
  requestId,
  formKey,
}: Readonly<ApprovalLetterUploadModalProps>): JSX.Element => {
  const [file, setFile] = React.useState<File>();
  const [error, setError] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const { uploadApprovalLetter } = useUploadApprovalLetterQuery();
  const onFilesChange = useCallback((files: File[]): void => {
    setFile(files[0]);
    setError('');
  }, []);

  const closeModal = (): void => {
    setError('');
    setLoading(false);
    setFile(undefined);
    toggleUploadModal();
  };
  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault();
    setLoading(true);
    setError('');
    const payLoad = { requestId, formData: { file } };
    uploadApprovalLetter(payLoad)
      .then(() => {
        setLoading(false);
        setFile(undefined);
        toggleUploadModal(requestId);
      })
      .catch(() => {
        setLoading(false);
        setError('An error occurred while uploading the file');
      });
  };

  return (
    <Modal
      dismissible
      open={isUploadModalOpen}
      onCancel={closeModal}
      className="letter-upload-modal"
      size="sm"
    >
      <form
        action="."
        autoComplete="off"
        className="approval-letter-upload"
        encType="multipart/form-data"
        method="post"
        noValidate
        onSubmit={handleSubmit}
      >
        <header>Upload approval letter</header>
        <div className="modal-body">
          {!!error && (
            <AlertBanner
              type="error"
              heading={error}
              className="co-alert--error"
            />
          )}
          <p className="modal-description">
            Uploading for <span className="text-name">{instructorName}</span>
          </p>
          <p className="modal-info">
            Max file size is 5MB. Supported file types are .pdf and .doc
          </p>
          <FileUpload
            key={formKey}
            accept={
              '.pdf,application/pdf,.doc,application/doc,application/msword,.docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            }
            onFilesChange={onFilesChange}
            variant="dnd"
            className="modal-upload-section"
            dropZoneText={'Drag and drop files here or click to upload'}
            maxSize={5}
            maxFileSize={5}
            onLoading={loading}
          />
        </div>
        <div className="modal-footer">
          <Button
            type={'submit'}
            variant="primary"
            disabled={!file || loading}
            className="upload-button"
          >
            {loading ? 'Uploading' : 'Upload'}
          </Button>
          <Button
            variant="secondary"
            onClick={closeModal}
            className="cancel-button"
          >
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ApprovalLetterUploadModal;
