import * as React from 'react';
import {
  Button,
  CheckboxGroup,
  Icon,
  Modal,
  TextInput,
  Toggle,
} from '@cae/cobalt-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useViewportSize } from '@/shared/hooks/useViewportSize';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import HasAccess from '@/shared/components/has-access/HasAccess';
import { useUserInfo } from '@/contexts/userInfo';
import { createExternalUser } from '../../api/createExternalUser';
import LoaderWithLabel from '../../../../shared/components/loader/Loader';
import { useGetAllRolesListQuery } from '../../api/useGetAllRolesListQuery';

import './CreateUser.css';
import { toastMessages } from '@/shared/helpers/toastMessages';
import Logger from '@/libs/logging/Logger';
import { useQueryClient } from '@tanstack/react-query';
import SFRedirectModal from '@/pages/users/sf-redirect-modal/SFRedirectModal';

interface ErrorResponse {
  detail: string;
}

export const roleOptionsHelperText = [
  {
    helperText:
      "Fully control of company's accesses and data for records, training information,  documents and billing.",
    value: 'Admin',
  },
  {
    helperText:
      'View invoices, make payments and access other financial functions.',
    value: 'Finance',
  },
  {
    helperText: 'View, download and manage crew records.',
    value: 'Records',
  },
  {
    helperText:
      'View and manage bookings, provide data for training and access documents.',
    value: 'Training',
  },
];
export interface RoleOptions {
  helperText: string;
  label: string;
  value: string;
  disabled: boolean;
}
type CreateUserFormData = Omit<z.infer<typeof createUserSchema>, 'roles'> & {
  roles: [string, ...string[]];
};

const createUserSchema = z.object({
  firstName: z.string().min(3).max(255).min(1, 'First name is required'),
  middleName: z.string().max(255),
  lastName: z.string().min(2).max(255).min(1, 'Last name is required'),
  email: z.string().email().min(1, 'Email is required'),
  active: z.boolean().default(true),
  roles: z.array(z.string()).min(1, 'Roles is required'),
});

type InactiveDialogActionsProps = {
  handleCancel: React.MouseEventHandler;
  handleConfirm: React.MouseEventHandler;
};

function InactiveDialogActions({
  handleCancel,
  handleConfirm,
}: InactiveDialogActionsProps): JSX.Element {
  return (
    <>
      <Button
        type="button"
        variant="secondary"
        className="primary-btn-color"
        onClick={handleCancel}
      >
        Cancel
      </Button>
      <Button type="button" variant="primary" onClick={handleConfirm}>
        Grant Access
      </Button>
    </>
  );
}

export function CreateUser(): JSX.Element {
  const { width } = useViewportSize();
  const { data: rolesOptions } = useGetAllRolesListQuery();
  const [rolesList, setRolesList] = React.useState<RoleOptions[]>([]);
  const rolesRef = React.useRef<RoleOptions[]>([]);
  const userInfo = useUserInfo();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    if (
      rolesOptions &&
      rolesOptions.length > 0 &&
      roleOptionsHelperText &&
      roleOptionsHelperText.length > 0
    ) {
      rolesRef.current = rolesOptions
        .filter(role => role.type === 'External')
        .map(item => {
          const helperText =
            roleOptionsHelperText.find(option => option.value === item.name)
              ?.helperText || '';
          return {
            label: item.name!,
            value: item.id!,
            helperText: helperText || '',
            disabled: false,
          };
        });
      rolesRef.current.sort((a, b) => a.label.localeCompare(b.label));
      setRolesList(rolesRef.current);
    } else {
      setRolesList([]);
    }
  }, [rolesOptions]);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = React.useState(false);
  const [openInactiveConfirm, setOpenInactiveConfirm] = React.useState(false);
  const [showSFRedirectModal, setShowSFRedirectModal] = React.useState(false);
  const defaultValues = {
    firstName: state?.userData?.firstName || '',
    lastName: state?.userData?.lastName || '',
    middleName: '',
    email: state?.userData?.emailInUserService || '',
    active: true,
    roles: [],
  };
  const organizationId =
    state?.userData?.organizationId ?? userInfo?.selectedOrganization?.id;
  const { control, formState, handleSubmit, getValues, reset, watch } =
    useForm<CreateUserFormData>({
      mode: 'onChange',
      resolver: zodResolver(createUserSchema),
      defaultValues,
    });
  const { isValid } = formState;
  const watchFields = watch(['active']);
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] =
    React.useState(false);

  const toggleRightModal = (): void => {
    setIsCreateUserModalOpen(!isCreateUserModalOpen);
  };

  React.useEffect(() => {
    const clearSession = (): void => {
      sessionStorage.removeItem('sfaccountid');
      sessionStorage.removeItem('sfmastercontactid');
    };
    return () => {
      if (location.pathname !== '/users') {
        clearSession();
      }
    };
  }, []);

  React.useEffect(() => {
    if (
      state?.userData?.isOrganizationExists &&
      !state?.userData?.isAlreadyExistsInOrg
    ) {
      setShowSFRedirectModal(true);
      const timer = setTimeout(() => {
        setShowSFRedirectModal(false);
        setIsCreateUserModalOpen(true);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [state]);

  const onCreateUserValid = (data: CreateUserFormData): void => {
    setLoading(true);
    createExternalUser(data, organizationId)
      .then((res: AxiosResponse | AxiosError) => {
        if (res instanceof Error) {
          if (res.response && res.response.status === 500) {
            const errorData = res.response.data as ErrorResponse;
            toastMessages.error(errorData.detail);
            toggleRightModal();
            setLoading(false);
            navigate('/users');
          } else {
            toastMessages.error('Please Contact Support');
            toggleRightModal();
            setLoading(false);
            navigate('/users');
          }
        } else {
          if (res.status === 201) {
            toastMessages.success('New member added');
            queryClient.invalidateQueries({ queryKey: ['users'] });
            reset();
            setRolesValue([]);
            toggleRightModal();
            setLoading(false);
            navigate('/users');
          }
        }
      })
      .catch((error: AxiosError) => {
        toastMessages.error('Please Contact Support');
        toggleRightModal();
        setLoading(false);
        navigate('/users');

        Logger.log({
          level: 'error',
          message: `Error: ${error}`,
          type: 'code',
        });
      });
  };

  const handleConfirm = (): void => {
    const statusValue = getValues('active');
    if (!statusValue || !watchFields[0]) {
      setOpenInactiveConfirm(true);
    } else {
      handleSubmit(onCreateUserValid)();
    }
  };

  const handleCancelCreateUser = (): void => {
    reset();
    setRolesValue([]);
    toggleRightModal();
  };

  const handleInactiveGrant = (): void => {
    handleSubmit(onCreateUserValid)();
    setOpenInactiveConfirm(false);
  };

  const handleCancelInactive = (): void => {
    setOpenInactiveConfirm(false);
  };

  const [rolesValue, setRolesValue] = React.useState<string[]>([]);

  const handleRoleChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): string[] => {
    let nextRolesValue: string[] = [];
    const isAdmin = rolesList.some(
      (i: RoleOptions) => i.value === e.target.value && i.label === 'Admin'
    );

    if (isAdmin && e.target.checked) {
      let temp: string[] = [];
      rolesList.forEach((item: RoleOptions) => {
        if (item.label === 'Admin') {
          temp = [...temp, item.value];
          item.disabled = false;
        } else {
          item.disabled = true;
        }
      });
      e.target.disabled = false;
      nextRolesValue = temp;
    } else if (isAdmin && !e.target.checked) {
      rolesList.forEach((item: RoleOptions) => {
        item.disabled = false;
      });
      nextRolesValue = [];
    } else if (rolesValue.includes(e.target.value)) {
      nextRolesValue = rolesValue.filter((i: string) => i !== e.target.value);
    } else {
      nextRolesValue = [...rolesValue];
      nextRolesValue.push(e.target.value);
    }

    setRolesList(rolesList);
    setRolesValue(nextRolesValue);
    return nextRolesValue;
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const next = handleRoleChange(e);
    return next;
  };

  return (
    <HasAccess permissions="add_user">
      <Button
        type="button"
        className={`btn-add-user ${width < 768 ? 'btn-add-user-width' : ''}`}
        variant="primary"
        onClick={toggleRightModal}
      >
        {width > 768 ? <Icon id="add-solid" /> : ''}
        Add User
      </Button>
      <Modal
        animate
        open={isCreateUserModalOpen}
        placement={width < 480 ? 'bottom' : 'right'}
        dismissible
        onCancel={handleCancelCreateUser}
        onConfirm={handleConfirm}
        size="sm"
        className="create-user--dialog"
      >
        {loading && (
          <div className="create-user-loader-overlay">
            <LoaderWithLabel title="Creating user" />
          </div>
        )}
        {!loading && (
          <form
            className="create-user-form"
            onSubmit={handleSubmit(onCreateUserValid)}
          >
            <h1>{t('createUser.title')}</h1>
            <h1 className="personal-information-heading">
              {t('createUser.personalInformation')}
            </h1>
            <div className="create-user-form-fields">
              <Controller
                name="firstName"
                control={control}
                render={({
                  field,
                  fieldState: { invalid, isTouched, isDirty, error },
                }) => (
                  <TextInput
                    label="First name *"
                    field={{ ...field, placeholder: 'Type first name' }}
                    meta={{
                      dirty: isDirty,
                      error: error?.message || '',
                      touched: isTouched,
                      valid: !invalid,
                    }}
                  />
                )}
              />

              <Controller
                name="middleName"
                control={control}
                render={({
                  field,
                  fieldState: { invalid, isTouched, isDirty, error },
                }) => (
                  <TextInput
                    label="Middle name"
                    field={{ ...field, placeholder: 'Type middle name' }}
                    meta={{
                      dirty: isDirty,
                      error: error?.message || '',
                      touched: isTouched,
                      valid: !invalid,
                    }}
                  />
                )}
              />
              <Controller
                name="lastName"
                control={control}
                render={({
                  field,
                  fieldState: { invalid, isTouched, isDirty, error },
                }) => (
                  <TextInput
                    label="Last name *"
                    field={{ ...field, placeholder: 'Type last name' }}
                    meta={{
                      dirty: isDirty,
                      error: error?.message || '',
                      touched: isTouched,
                      valid: !invalid,
                    }}
                  />
                )}
              />
              <Controller
                name="email"
                control={control}
                render={({
                  field,
                  fieldState: { invalid, isTouched, isDirty, error },
                }) => (
                  <TextInput
                    label="Email *"
                    field={{
                      ...field,
                      placeholder: 'Type email',
                      disabled: !!defaultValues.email,
                    }}
                    meta={{
                      dirty: isDirty,
                      error: error?.message || '',
                      touched: isTouched,
                      valid: !invalid,
                    }}
                  />
                )}
              />
              <div>
                <Controller
                  name="active"
                  control={control}
                  defaultValue
                  render={({
                    field,
                    fieldState: { invalid, isTouched, isDirty, error },
                  }) => (
                    <Toggle
                      field={{
                        ...field,
                        defaultChecked: true,
                        value: field.value ? 'true' : 'false',
                      }}
                      className="create-user-toggle"
                      meta={{
                        dirty: isDirty,
                        error: error?.message || '',
                        touched: isTouched,
                        valid: !invalid,
                      }}
                      label="User Status"
                      orientation="vertical"
                    />
                  )}
                />
              </div>
              <h3 style={{ margin: '0.5rem 0' }}> {t('createUser.role')}</h3>
              <Controller
                name="roles"
                control={control}
                render={({ field }) => (
                  <CheckboxGroup
                    options={rolesList}
                    orientation="vertical"
                    field={{
                      ...field,
                      name: 'roles',
                      onChange: e => field.onChange(handleChange(e)),
                      value: rolesValue,
                    }}
                  />
                )}
              />
            </div>

            <div className="create-user-footer">
              <Button
                type="button"
                variant="ghost"
                className="primary-btn-color"
                onClick={handleCancelCreateUser}
              >
                Cancel
              </Button>
              <Button
                type="button"
                variant="primary"
                disabled={!isValid}
                onClick={handleConfirm}
              >
                Grant Access
              </Button>
            </div>
          </form>
        )}
      </Modal>

      <Modal
        className="grant-dialog-mode"
        open={openInactiveConfirm}
        onCancel={handleCancelInactive}
        onConfirm={handleInactiveGrant}
        actions={InactiveDialogActions}
        placement="center"
        size="sm"
      >
        <h3 className="inactive-user-status--title">
          You are adding a new user as inactive
        </h3>
        <p>
          They will be added to the system but has not been notified until made
          active at any time.{' '}
        </p>
      </Modal>
      {showSFRedirectModal && <SFRedirectModal />}
    </HasAccess>
  );
}
