import {
  InstructorApprovalLetterDto,
  InstructorManagementService,
} from '@/open-api';

export function useUploadApprovalLetterQuery(): {
  uploadApprovalLetter: ({
    requestId,
    formData,
  }: {
    requestId: string;
    formData: InstructorApprovalLetterDto;
  }) => Promise<unknown>;
} {
  const uploadApprovalLetter = async ({
    requestId,
    formData,
  }: {
    requestId: string;
    formData: InstructorApprovalLetterDto;
  }): Promise<unknown> =>
    await InstructorManagementService.uploadApprovalLetter({
      requestId: requestId,
      formData,
    });
  return { uploadApprovalLetter };
}
