import {
  InstructorApprovalRequestsMetadataDto,
  InstructorManagementService,
  InstructorPendingApprovalLettersDto,
} from '@/open-api';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

export function usePendingLetterCountQuery(): UseQueryResult<
  InstructorApprovalRequestsMetadataDto,
  Error
> {
  return useQuery({
    queryKey: ['pending-letters-count'],
    queryFn: () => InstructorManagementService.getApprovalRequestsMeta({}),
  });
}

export function usePendingLetterQuery(
  pendingLettersDrawerOpen: boolean
): UseQueryResult<InstructorPendingApprovalLettersDto, Error> {
  return useQuery({
    queryKey: ['pending-approval-letters'],
    queryFn: () => InstructorManagementService.getPendingApprovalLetters({}),
    enabled: pendingLettersDrawerOpen,
  });
}
