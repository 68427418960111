/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DocumentChangeRequest } from '../models/DocumentChangeRequest';
import type { DocumentDto } from '../models/DocumentDto';
import type { DocumentElement } from '../models/DocumentElement';
import type { DocumentUploadRequest } from '../models/DocumentUploadRequest';
import type { DocumentUploadResponse } from '../models/DocumentUploadResponse';
import type { ListOfDocuments } from '../models/ListOfDocuments';
import type { PagedListOfDocumentDto } from '../models/PagedListOfDocumentDto';
import type { QuickLinksResponse } from '../models/QuickLinksResponse';
import type { ViewedDocumentDto } from '../models/ViewedDocumentDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DocumentsManagementService {
  /**
   * @returns DocumentUploadResponse OK
   * @throws ApiError
   */
  public static uploadDocuments({
    organizationId,
    formData,
  }: {
    organizationId?: string;
    formData?: DocumentUploadRequest;
  }): CancelablePromise<DocumentUploadResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/documents/upload',
      headers: {
        'Organization-Id': organizationId,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static sendDocumentChangeRequest({
    organizationId,
    formData,
  }: {
    organizationId?: string;
    formData?: DocumentChangeRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/documents/change-request',
      headers: {
        'Organization-Id': organizationId,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns ListOfDocuments OK
   * @throws ApiError
   */
  public static listDocuments({
    pageIndex,
    pageSize = 50,
    folderId,
    type = 'company',
    status,
    searchText,
    organizationId,
  }: {
    /**
     * Page index
     */
    pageIndex?: number;
    /**
     * Page size
     */
    pageSize?: number;
    folderId?: string;
    /**
     * Type of the documents
     */
    type?: 'cae' | 'company' | 'submitted';
    /**
     * Document status
     */
    status?: Array<'submitted' | 'in-review' | 'completed'>;
    /**
     * Search term for additional filtering by file name
     */
    searchText?: string;
    organizationId?: string;
  }): CancelablePromise<ListOfDocuments> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/documents',
      headers: {
        'Organization-Id': organizationId,
      },
      query: {
        pageIndex: pageIndex,
        pageSize: pageSize,
        folderId: folderId,
        type: type,
        status: status,
        searchText: searchText,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns DocumentDto OK
   * @throws ApiError
   */
  public static getDocumentDetails({
    documentId,
    organizationId,
  }: {
    documentId: string;
    organizationId?: string;
  }): CancelablePromise<DocumentDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/documents/{documentId}',
      path: {
        documentId: documentId,
      },
      headers: {
        'Organization-Id': organizationId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static getDocumentAsBlob({
    documentId,
    organizationId,
  }: {
    documentId: string;
    organizationId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/documents/{documentId}/blob',
      path: {
        documentId: documentId,
      },
      headers: {
        'Organization-Id': organizationId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns ViewedDocumentDto OK
   * @throws ApiError
   */
  public static getViewedDocuments({
    userId,
    organizationId,
  }: {
    userId: string;
    organizationId: string;
  }): CancelablePromise<Array<ViewedDocumentDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/documents/users/{userId}/viewed',
      path: {
        userId: userId,
      },
      headers: {
        'Organization-Id': organizationId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns DocumentElement OK
   * @throws ApiError
   */
  public static getDocumentTree({
    type,
    searchText,
    organizationId,
  }: {
    type?: string;
    /**
     * Search term for additional filtering by file name
     */
    searchText?: string;
    /**
     * Search term for additional filtering by organizationId
     */
    organizationId?: string;
  }): CancelablePromise<Array<DocumentElement>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/documents/tree',
      query: {
        type: type,
        searchText: searchText,
        organizationId: organizationId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns PagedListOfDocumentDto OK
   * @throws ApiError
   */
  public static searchDocuments({
    searchText,
    pageIndex,
    pageSize = 50,
    folderId,
    type = 'company',
    status,
    organizationId,
  }: {
    /**
     * Search term for additional filtering by file name
     */
    searchText: string;
    /**
     * Page index
     */
    pageIndex?: number;
    /**
     * Page size
     */
    pageSize?: number;
    folderId?: string;
    /**
     * Type of the documents
     */
    type?: 'cae' | 'company' | 'submitted';
    /**
     * Submitted document status
     */
    status?: Array<'submitted' | 'in-review' | 'completed'>;
    organizationId?: string;
  }): CancelablePromise<PagedListOfDocumentDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/documents/search',
      headers: {
        'Organization-Id': organizationId,
      },
      query: {
        pageIndex: pageIndex,
        pageSize: pageSize,
        folderId: folderId,
        type: type,
        status: status,
        searchText: searchText,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns QuickLinksResponse OK
   * @throws ApiError
   */
  public static searchQuickLinks(): CancelablePromise<QuickLinksResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/documents/quick-links',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
}
