import { DocumentElement } from '@/open-api';
import { TextInput, Icon } from '@cae/cobalt-react';

export function SearchDocumentsUpdatedInput({
  jsonData,
  keyword = '',
  setKeyword,
  setShowResults,
}: {
  readonly jsonData: DocumentElement[];
  readonly handleFolderClick?: (arg: string) => void;
  readonly keyword: string;
  readonly setKeyword: (arg: string) => void;
  readonly setShowResults?: (arg: boolean) => void;
}): JSX.Element {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    e.preventDefault();
    setKeyword(e.target.value ?? '');
  };

  const handleClear: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    setKeyword('');
    if (typeof setShowResults === 'function') setShowResults(false);
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter' && jsonData) {
      if (typeof setShowResults === 'function') setShowResults(true);
    }
  };

  return (
    <div style={{ flex: '0 1 50%' }}>
      <TextInput
        onChange={handleChange}
        className="search-input search-documents"
        field={{
          placeholder: 'Search by document name',
          value: keyword,
          onKeyDown: handleKeyDown,
        }}
        prefixSlot={<Icon id="search-outline" size="md" />}
        suffixSlot={
          keyword ? (
            <button
              aria-label="Clear"
              className="btn--clear"
              onClick={handleClear}
              title="Clear"
              type="button"
            >
              <Icon id="close-circle-solid" size="sm" />
            </button>
          ) : (
            ''
          )
        }
      />
    </div>
  );
}

SearchDocumentsUpdatedInput.displayName = 'SearchDocumentsUpdatedInput';
