import './LoaderCircularComponent.css';

export const LoaderCircularComponent: React.FC = () => {
  return (
    <div className="loader-circular-container">
      <div className="loader-circular"></div>
    </div>
  );
};

export default LoaderCircularComponent;
