import { useCurrentRoles } from '@/shared/hooks/useCurrentRole';
import { DashboardLatestRecords } from '../dashboard-latest-records/DashboardLatestRecords';
import { DashboardNewAwaiting } from '../dashboard-new-awaiting/DashboardNewAwaiting';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DashboardAwaiting } from '../dashboard-awaiting/DashboardAwaiting';

function RoleBasedTableWrapper(): JSX.Element {
  const currentRoles = useCurrentRoles();
  const { HomepageNewAlertCardsWebApp } = useFlags();
  return (
    <div>
      {!HomepageNewAlertCardsWebApp ? (
        <DashboardNewAwaiting />
      ) : currentRoles &&
        !currentRoles?.includes('Admin') &&
        !currentRoles?.includes('Training') &&
        currentRoles?.includes('Records') ? (
        <DashboardLatestRecords />
      ) : (
        <DashboardAwaiting />
      )}
    </div>
  );
}

export default RoleBasedTableWrapper;
