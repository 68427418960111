/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DisciplineTypeDto } from '../models/DisciplineTypeDto';
import type { InstructorApprovalLetterDto } from '../models/InstructorApprovalLetterDto';
import type { InstructorApprovalRequestDto } from '../models/InstructorApprovalRequestDto';
import type { InstructorApprovalRequestsMetadataDto } from '../models/InstructorApprovalRequestsMetadataDto';
import type { InstructorAvailabilityDto } from '../models/InstructorAvailabilityDto';
import type { InstructorDetailsDto } from '../models/InstructorDetailsDto';
import type { InstructorListDto } from '../models/InstructorListDto';
import type { InstructorMetadataDto } from '../models/InstructorMetadataDto';
import type { InstructorPendingApprovalLettersDto } from '../models/InstructorPendingApprovalLettersDto';
import type { InstructorProgramAlertDto } from '../models/InstructorProgramAlertDto';
import type { InstructorProgramMetadataDto } from '../models/InstructorProgramMetadataDto';
import type { InstructorProgramSpecificMetaDto } from '../models/InstructorProgramSpecificMetaDto';
import type { PagedListOfCheckAirmenPerPlatformDto } from '../models/PagedListOfCheckAirmenPerPlatformDto';
import type { PagedListOfInstructorProgramDto } from '../models/PagedListOfInstructorProgramDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class InstructorManagementService {
  /**
   * @returns any Created
   * @throws ApiError
   */
  public static addApprovalRequests({
    requestBody,
  }: {
    requestBody: Array<InstructorApprovalRequestDto>;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v2/instructors/approval-requests',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns any Created
   * @throws ApiError
   */
  public static uploadApprovalLetter({
    requestId,
    formData,
  }: {
    requestId: string;
    formData?: InstructorApprovalLetterDto;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v2/instructors/approval-requests/{requestId}/approval-letters',
      path: {
        requestId: requestId,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns InstructorListDto OK
   * @throws ApiError
   */
  public static getAllInstructorsForProgramAtTrainingCenter({
    trainingCenterId,
    program,
    organizationId,
  }: {
    /**
     * TrainingCenterId
     */
    trainingCenterId: string;
    /**
     * Program
     */
    program: string;
    organizationId?: string;
  }): CancelablePromise<InstructorListDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v2/instructors',
      headers: {
        'Organization-Id': organizationId,
      },
      query: {
        trainingCenterId: trainingCenterId,
        program: program,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns PagedListOfInstructorProgramDto OK
   * @throws ApiError
   */
  public static getInstructorPrograms({
    trainingCenterId,
    pageIndex,
    pageSize = 10,
    sortBy = 'program',
    searchText,
    organizationId,
  }: {
    /**
     * TrainingCenterId
     */
    trainingCenterId: string;
    /**
     * Page index
     */
    pageIndex?: number;
    /**
     * Page size
     */
    pageSize?: number;
    /**
     * Property used for sorting the result
     */
    sortBy?: string;
    /**
     * Search term for additional filtering on any of program
     */
    searchText?: string;
    organizationId?: string;
  }): CancelablePromise<PagedListOfInstructorProgramDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v2/instructors/programs',
      headers: {
        'Organization-Id': organizationId,
      },
      query: {
        pageIndex: pageIndex,
        pageSize: pageSize,
        sortBy: sortBy,
        searchText: searchText,
        trainingCenterId: trainingCenterId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns InstructorProgramSpecificMetaDto OK
   * @throws ApiError
   */
  public static getInstructorCountAtTrainingCenterForProgram({
    program,
    organizationId,
  }: {
    /**
     * Program
     */
    program: string;
    organizationId?: string;
  }): CancelablePromise<InstructorProgramSpecificMetaDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v2/instructors/program/meta',
      headers: {
        'Organization-Id': organizationId,
      },
      query: {
        program: program,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns InstructorProgramMetadataDto OK
   * @throws ApiError
   */
  public static getInstructorProgramMeta({
    organizationId,
  }: {
    organizationId?: string;
  }): CancelablePromise<InstructorProgramMetadataDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v2/instructors/meta',
      headers: {
        'Organization-Id': organizationId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns InstructorDetailsDto OK
   * @throws ApiError
   */
  public static getInstructorDetails({
    instructorId,
    organizationId,
  }: {
    /**
     * InstructorId
     */
    instructorId: string;
    organizationId?: string;
  }): CancelablePromise<InstructorDetailsDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v2/instructors/details',
      headers: {
        'Organization-Id': organizationId,
      },
      query: {
        instructorId: instructorId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns InstructorAvailabilityDto OK
   * @throws ApiError
   */
  public static getAvailableInstructors({
    discipline,
    program,
    organizationId,
    trainingCenterId,
  }: {
    /**
     * Discipline Type
     */
    discipline: DisciplineTypeDto;
    /**
     * Program
     */
    program: string;
    organizationId?: string;
    /**
     * TrainingCenterId
     */
    trainingCenterId?: string;
  }): CancelablePromise<InstructorAvailabilityDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v2/instructors/available',
      headers: {
        'Organization-Id': organizationId,
      },
      query: {
        trainingCenterId: trainingCenterId,
        discipline: discipline,
        program: program,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns InstructorPendingApprovalLettersDto OK
   * @throws ApiError
   */
  public static getPendingApprovalLetters({
    organizationId,
  }: {
    organizationId?: string;
  }): CancelablePromise<InstructorPendingApprovalLettersDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v2/instructors/approval-requests/pending-letters',
      headers: {
        'Organization-Id': organizationId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns InstructorApprovalRequestsMetadataDto OK
   * @throws ApiError
   */
  public static getApprovalRequestsMeta({
    organizationId,
  }: {
    organizationId?: string;
  }): CancelablePromise<InstructorApprovalRequestsMetadataDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v2/instructors/approval-requests/meta',
      headers: {
        'Organization-Id': organizationId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns InstructorProgramAlertDto OK
   * @throws ApiError
   */
  public static getInstructorProgramAlerts({
    organizationId,
  }: {
    organizationId?: string;
  }): CancelablePromise<Array<InstructorProgramAlertDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v2/instructors/alerts',
      headers: {
        'Organization-Id': organizationId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns binary OK
   * @throws ApiError
   */
  public static generateInstructorReportAsBlob({
    location,
    reportType = 'check-airmen',
    platform,
    organizationId,
  }: {
    /**
     * Location
     */
    location: string;
    /**
     * Type of the report
     */
    reportType?: 'instructor-pilot' | 'check-airmen' | 'recurrent' | 'approved';
    /**
     * Platform
     */
    platform?: string;
    organizationId?: string;
  }): CancelablePromise<Blob> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/instructors/report',
      headers: {
        'Organization-Id': organizationId,
      },
      query: {
        reportType: reportType,
        location: location,
        platform: platform,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns InstructorMetadataDto OK
   * @throws ApiError
   */
  public static getInstructorMetadata({
    organizationId,
  }: {
    organizationId?: string;
  }): CancelablePromise<InstructorMetadataDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/instructors/meta',
      headers: {
        'Organization-Id': organizationId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns PagedListOfCheckAirmenPerPlatformDto OK
   * @throws ApiError
   */
  public static getCheckAirmenPerPlatform({
    pageIndex,
    pageSize = 10,
    sortBy = 'approvedCheckAirmen',
    organizationId,
  }: {
    /**
     * Page index
     */
    pageIndex?: number;
    /**
     * Page size
     */
    pageSize?: number;
    /**
     * Property used for sorting the result
     */
    sortBy?: string;
    organizationId?: string;
  }): CancelablePromise<PagedListOfCheckAirmenPerPlatformDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/instructors/check-airmen',
      headers: {
        'Organization-Id': organizationId,
      },
      query: {
        pageIndex: pageIndex,
        pageSize: pageSize,
        sortBy: sortBy,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static cancelApprovalRequest({
    requestId,
  }: {
    requestId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v2/instructors/approval-requests/{requestId}',
      path: {
        requestId: requestId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
}
