import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Card, CardHeader } from '@cae/cobalt-react';
import CurrencyFormat from '@/shared/components/format-currency/FormatCurrency';
import InvoiceDownloadButton from '../invoice-download-button/InvoiceDownloadButton';
import './InvoiceDuePaymentsAlertDetails.css';

interface Alert {
  transactionNumber: string;
  amount: number;
  currency: string;
  accountReceivableId: string;
}

const InvoiceDuePaymentsAlertDetails: React.FC = () => {
  const location = useLocation();
  const alertData =
    (location.state as { alertData?: Alert[] })?.alertData || [];

  const getDescription = (numberOfAlerts: number): string => {
    return `You have ${numberOfAlerts} alerts for invoices past due.`;
  };

  return (
    <aside className="invoice-payments--alert-details">
      {alertData.length === 0 ? (
        <p>No past due invoices available.</p>
      ) : (
        <>
          <h3>Your Past Due Invoices</h3>
          <p>{getDescription(alertData.length)}</p>
          <div className="scroll-container">
            {alertData.map((alert: Alert) => (
              <Link
                to={`/finance/${alert.accountReceivableId}`}
                key={alert.transactionNumber}
              >
                <Card
                  key={alert.transactionNumber || alert.accountReceivableId}
                >
                  <CardHeader>
                    <hgroup>
                      <h4 className="invoice-payments--alert-details_transaction-number">
                        {alert.transactionNumber || 'N/A'}
                      </h4>
                      <p>
                        <CurrencyFormat
                          value={alert.amount}
                          currency={alert.currency}
                        />
                      </p>
                    </hgroup>
                    <button
                      type="button"
                      onClick={(e: React.SyntheticEvent) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                      onKeyDown={(e: React.SyntheticEvent) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    >
                      <InvoiceDownloadButton
                        invoiceId={alert.accountReceivableId}
                        label="Download"
                        fileName={alert.transactionNumber}
                      />
                    </button>
                  </CardHeader>
                </Card>
              </Link>
            ))}
          </div>
        </>
      )}
    </aside>
  );
};

InvoiceDuePaymentsAlertDetails.displayName = 'InvoiceDuePaymentsAlertDetails';

export default InvoiceDuePaymentsAlertDetails;
