import { DownloadButton } from '@/shared/components';
import { toastMessages } from '@/shared/helpers/toastMessages';
import { useDownloadFile } from '@/shared/hooks/useDownloadFile';

type InvoiceDownloadButtonProps = {
  readonly invoiceId: string;
  readonly label?: string;
  readonly fileName: string;
};
function InvoiceDownloadButton({
  invoiceId,
  label,
  fileName,
}: InvoiceDownloadButtonProps): JSX.Element {
  const { downloadFile, status } = useDownloadFile({
    onSuccess: () => {
      toastMessages.success(`Invoice downloaded successfully`);
    },
    onError: () => {
      toastMessages.error(`Invoice download failed`);
    },
  });

  const handleClick = (): void => {
    downloadFile({
      url: `/invoices/${invoiceId}/blob`,
      fileName: `invoice_${fileName}.pdf`,
    });
  };

  return (
    <DownloadButton
      onClick={handleClick}
      loading={status === 'pending'}
      label={label}
      buttonType="ghost"
    />
  );
}

export default InvoiceDownloadButton;
