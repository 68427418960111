import { Avatar, Card, Icon } from '@cae/cobalt-react';
import './OrganizationSelector.css';
import { useUserInfo } from '@/contexts/userInfo';
import { useNavigate } from 'react-router-dom';
import APIConfig from '@/configs/urlConfig';
import axios from 'axios';
import logo from '../../../assets/images/cae-logo.svg';
import LoaderWithLabel from '@/shared/components/loader/Loader';
import { useTranslation } from 'react-i18next';
import useGetUserOrganizationsQuery from '../api/useGetUserOrganizations';
import { useEffect } from 'react';
const updateOrganization = async (orgId: string): Promise<string> => {
  const tempToken = JSON.parse(localStorage.getItem('idToken') ?? '{}');
  const config = {
    crossDomain: true,
    headers: {
      Authorization: `Bearer ${tempToken}`,
      'Ocp-Apim-Subscription-Key': APIConfig.subscriptionKey,
    },
  };
  const res = await axios.patch(
    `${APIConfig.apiUrl}/users/me/selectedOrganization`,
    {
      selectedOrganization: orgId,
    },
    config
  );
  return res.data;
};

function OrganizationSelector(): JSX.Element {
  const userInfo = useUserInfo();
  const { data: userOrganizations, isLoading } = useGetUserOrganizationsQuery();
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    if (userInfo.isVisitorOnly) {
      navigate('/home');
    }
  }, [navigate, userInfo.isVisitorOnly]);
  const handleOrgUpdate = async (orgId: string | undefined): Promise<void> => {
    if (orgId)
      await updateOrganization(orgId).then(() => {
        navigate('/org-switcher');
      });
  };

  return (
    <div>
      {userInfo.id && !isLoading ? (
        <div className="organization-selector-bg">
          <div className="organization-selector__nav-container">
            <img src={logo} className="nav-cae-logo" alt="cae-logo" />
            <div className="organization-selector__help-container">
              <a
                className="nav-help"
                href="https://help.cae.com/"
                target="_blank"
                rel="noreferrer"
              >
                <div>{t('common.help')}</div>{' '}
                <Icon id="help-circle-outline" size="sm" color="#fff" />
              </a>
            </div>
          </div>
          <div className="organization-selector-wrapper">
            <div className="organization-selector__title-container">
              <div className="organization-selector__title">CAE</div>
              <div className="organization-selector__sub_title">Connect</div>
            </div>
            <Card>
              <div className="organization-selector--selector-parent">
                <h3>What organization do you want to sign in as ?</h3>

                {userOrganizations?.map(organization => (
                  <button
                    key={organization.id}
                    onClick={() => handleOrgUpdate(organization?.id)}
                    className="organization-selector--selector"
                  >
                    <Avatar
                      size="sm"
                      initials={organization?.name
                        ?.substring(0, 2)
                        ?.toUpperCase()}
                    />{' '}
                    <p>{organization.name}</p>
                  </button>
                ))}
              </div>
              <div className="organization-selector--footer">
                <Icon id="info-circle-solid" size="sm" />
                <p>
                  You can switch your organization at any time in your profile
                  section.
                </p>
              </div>
            </Card>
            <span className="organization-selector-wrapper--footer">
              Copyright ©2023, CAE Inc. All rights reserved.
            </span>
          </div>
        </div>
      ) : (
        <LoaderWithLabel title="Loading Organization" />
      )}
    </div>
  );
}

export default OrganizationSelector;
