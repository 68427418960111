import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { RecordDetailsResponse, RecordManagementService } from '@/open-api';

export const useGetRecordsDetailsQuery = <Data>(
  recordId: string,
  select?: (data: RecordDetailsResponse) => Data
): UseQueryResult<Data, unknown> => {
  return useQuery({
    queryKey: ['records', recordId],
    queryFn: () => RecordManagementService.getRecordDetails({ recordId }),
    select,
  });
};
