import { UserManagementService, ApiError } from '@/open-api';
import { ChangeRequestForm } from '@/shared/components';
import { PostResponseStatus } from '@/shared/types';
import { AlertBanner, AlertMessage, Button, Dialog } from '@cae/cobalt-react';
import * as React from 'react';
import { SubmitHandler, FieldValues } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { generateRandomValue } from '@/shared/helpers/generateRandomValue';
import { BackNavigation } from '../back-navigation/BackNavigation';

type ModalActionsProps = {
  handleCancel: React.MouseEventHandler;
  handleConfirm: React.MouseEventHandler;
};

function DiscardModalActions({
  handleCancel,
  handleConfirm,
}: Readonly<ModalActionsProps>): JSX.Element {
  return (
    <>
      <Button
        type="button"
        variant="secondary"
        className="primary-btn-color"
        onClick={handleCancel}
      >
        Cancel
      </Button>
      <Button type="button" variant="primary" onClick={handleConfirm}>
        Discard
      </Button>
    </>
  );
}

function StatusSplash({
  onRefresh,
  status,
}: Readonly<{
  onRefresh: React.MouseEventHandler<HTMLButtonElement>;
  status: PostResponseStatus;
}>): JSX.Element {
  return (
    <>
      {status.type === 'success' && (
        <AlertMessage type="success" heading={status.message}>
          <p>
            Our team will get back to you within two business days with a
            follow-up on your request. Note that requests are subject to change
            depending on availability, feasibility and other factors.
          </p>
          <p>
            <button type="button" className="anchor" onClick={onRefresh}>
              Create another request?
            </button>
          </p>
        </AlertMessage>
      )}
      {status.type === 'error' && (
        <AlertBanner
          type="error"
          heading={status.heading}
          style={{ marginBottom: '1.5rem' }}
        >
          <p>{status.message}</p>
        </AlertBanner>
      )}
      {status.type !== 'success' && (
        <AlertMessage type="info" heading="" inline>
          Our team will get back to you within two business days with a
          follow-up on your request. Note that requests are subject to change
          depending on availability, feasibility and other factors.
        </AlertMessage>
      )}
    </>
  );
}
function PageHeader({
  onRefresh,
  status,
}: Readonly<{
  onRefresh: React.MouseEventHandler<HTMLButtonElement>;
  status: PostResponseStatus;
}>): JSX.Element {
  return (
    <header>
      <h2>Request</h2>
      <StatusSplash onRefresh={onRefresh} status={status} />
    </header>
  );
}

export default function UserChangeRequest(): JSX.Element {
  const navigate = useNavigate();
  const { userId = '' } = useParams();
  const [key, setKey] = React.useState<string>(generateRandomValue());
  const [isDiscardDialogOpen, setIsDiscardDialogOpen] = React.useState(false);
  const [status, setStatus] = React.useState<PostResponseStatus>({
    type: 'idle',
  });
  const handleCancelDiscard = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    setIsDiscardDialogOpen(false);
  };

  const onRefresh = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    setStatus({ type: 'idle' });
    setKey(generateRandomValue());
  };

  const handleDiscard: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    setIsDiscardDialogOpen(false);
    navigate(`/users/${userId}`);
  };
  const handleCancel = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    setIsDiscardDialogOpen(true);
  };

  const onSubmit: SubmitHandler<FieldValues> = async data => {
    setStatus({ type: 'pending' });
    const { title, details: text } = data;

    try {
      await UserManagementService.sendUserChangeRequest({
        id: userId,
        requestBody: {
          title,
          text,
        },
      });
      setStatus({
        type: 'success',
        heading: '',
        message: 'Request submitted successfully!',
      });
    } catch (error) {
      if (error instanceof ApiError) {
        setStatus({
          type: 'error',
          heading: 'API error occurred',
          message:
            'Your message has not yet been sent. Try again, or contact the administrators.',
        });
      } else {
        setStatus({
          type: 'error',
          heading: 'Oops, an error occurred',
          message:
            'Your message has not yet been sent. Try again, or contact the administrators.',
        });
      }
    }
  };

  return (
    <div className="dialog-details__page request" key={key}>
      <BackNavigation />
      <PageHeader onRefresh={onRefresh} status={status} />
      <ChangeRequestForm
        initialValues={{ title: '', details: '' }}
        onCancel={handleCancel}
        onSubmit={onSubmit}
        status={status}
      />
      <Dialog
        modal
        className="reservation-request-page"
        dismissible
        open={isDiscardDialogOpen}
        onCancel={handleCancelDiscard}
        onConfirm={handleDiscard}
        actions={DiscardModalActions}
        placement="center"
        size="sm"
      >
        <h2>Discard change request?</h2>
        <p>You will lose all information entered for this request.</p>
      </Dialog>
    </div>
  );
}
