import * as React from 'react';
import { Link } from 'react-router-dom';
import { format, parse, parseISO } from 'date-fns';
import { Calendar, Icon } from '@cae/cobalt-react';

import {
  ReservationDetailsResponse,
  TrainingDailySessionsDto,
} from '@/open-api';

import { DaySchedule } from '../day-schedule/DaySchedule';

import { useSelectLocationCourseSchedule } from '../../api/hooks';
import { TrainingSchedule, TrainingSession } from '../../types';

import { ScheduleEmptyState } from '../empty-states/ScheduleEmptyState';

import './LocationScheduleFragment.css';

interface PossibleGivenDate {
  value?: Date;
  className?: string;
}

interface GivenDate {
  value: Date;
  className?: string;
}

function parseLocalDateTime(input: string): Date {
  return parse(input, 'yyyy-MM-dd HH:mm', new Date());
}

function getSessionDate(d: TrainingDailySessionsDto): PossibleGivenDate | null {
  let v = undefined;
  if (Array.isArray(d.sessions) && d.sessions.length) {
    v = parseLocalDateTime(
      `${d.sessions[0].localStartDate} ${d.sessions[0].localStartTime}`
    );
    return {
      value: v instanceof Date && !isNaN(v.getTime()) ? v : undefined,
      className: 'tentative',
    };
  } else if (d.currentDate) {
    // NOTE: We also need local time to set local value
    v = parse(d.currentDate, 'yyyy-MM-dd', new Date());
    return {
      value: v instanceof Date && !isNaN(v.getTime()) ? v : undefined,
      className: 'tentative',
    };
  } else {
    return null;
  }
}

function SessionCalendar({ data }: { data: TrainingSchedule }): JSX.Element {
  const referenceDate = parseLocalDateTime(
    `${data.localStartDate} ${data.localStartTime}`
  );
  const [selectedDate, setSelectedDate] = React.useState<Date | undefined>(
    referenceDate
  );

  React.useEffect(() => {
    setSelectedDate(
      parseLocalDateTime(`${data.localStartDate} ${data.localStartTime}`)
    );
  }, [data.localStartDate, data.localStartTime]);

  let sessions: TrainingSession[] = [];

  if (Array.isArray(data.dailySessions) && data.dailySessions.length) {
    if (!selectedDate) {
      sessions = data.dailySessions[0].sessions;
    } else {
      const f = format(selectedDate, 'yyyy-MM-dd');
      const x = data.dailySessions.find(d => {
        const y = d.currentDate;
        return y === f;
      });
      sessions = x ? x.sessions : [];
    }
  }

  const givenDates = Array.isArray(data.dailySessions)
    ? data.dailySessions.map(getSessionDate).filter(s => s && s.value)
    : [];

  return (
    <div className="master-detail">
      <Calendar
        givenDates={givenDates as GivenDate[]}
        minDate={parseLocalDateTime(
          `${data.localStartDate} ${data.localStartTime}`
        )}
        maxDate={parseLocalDateTime(
          `${data.localEndDate} ${data.localEndTime}`
        )}
        onDateChange={(date: Date) => {
          setSelectedDate(date);
        }}
        referenceDate={referenceDate}
        value={selectedDate}
      />
      <DaySchedule data={sessions ?? []} selectedDate={selectedDate} />
    </div>
  );
}

export function LocationScheduleFragment({
  reservationId,
  schedulerReservationId = '',
}: {
  reservationId: string;
  schedulerReservationId?: string;
}): null | JSX.Element {
  const { data } = useSelectLocationCourseSchedule(reservationId);
  const s = ReservationDetailsResponse.status;

  if (!data) {
    return null;
  }

  const { schedule, trainingLocation } = data;
  const scheduleNotAvailable =
    data.status === s.PROVISIONAL ||
    !schedule ||
    !schedule.scheduleAvailable ||
    !schedule.localStartDate ||
    !schedule.localStartTime ||
    !schedule.localEndDate ||
    !schedule.localEndTime;
  const currentUrl = window.location.href;

  const targetUrl = currentUrl.includes('/home')
    ? `/home/${reservationId}/location-schedule`
    : `/reservations/${reservationId}/location-schedule`;

  return (
    <section className="dialog-details__fragment location-schedule">
      <header>
        <h3>Locations and Schedule</h3>
        {!scheduleNotAvailable && (
          <span className="actions">
            <Link
              to={targetUrl}
              state={{
                originalSchedulerReservationId: schedulerReservationId,
              }}
            >
              View details
              <Icon id="arrow-right-solid" size="sm" />
            </Link>
          </span>
        )}
      </header>
      {!trainingLocation.name && <p>Training location not available yet.</p>}
      {trainingLocation && schedule.localStartDate && schedule.localEndDate ? (
        <p>
          From {format(parseISO(schedule.localStartDate), 'dd-MMM-yyyy')} to{' '}
          {format(parseISO(schedule.localEndDate), 'dd-MMM-yyyy')} at {''}
          {trainingLocation.displayName || trainingLocation.name}.
        </p>
      ) : null}
      {scheduleNotAvailable ? (
        <ScheduleEmptyState>
          <h5>Schedule not available yet.</h5>
        </ScheduleEmptyState>
      ) : (
        <SessionCalendar data={schedule} />
      )}
    </section>
  );
}
