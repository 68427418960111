import {
  useMutation,
  useQueryClient,
  UseMutationResult,
} from '@tanstack/react-query';
import { UserManagementService } from '@/open-api';

type SyncUserMutationCallback = {
  onSuccessCallback: () => void;
  onErrorCallback: () => void;
};

export const useSyncUserWithExternalService = ({
  onSuccessCallback,
  onErrorCallback,
}: SyncUserMutationCallback): UseMutationResult<
  unknown,
  unknown,
  { id: string },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id }: { id: string }) => {
      const response = await UserManagementService.syncUserWithExternalService({
        id,
      });
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['users'] });
        queryClient.invalidateQueries({ queryKey: ['userSync'] });

        onSuccessCallback();
      },
      onError: error => {
        onErrorCallback();
        throw error;
      },
    }
  );
};
