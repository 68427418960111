import * as React from 'react';
import { Icon } from '@cae/cobalt-react';
import './Breadcrumbs.css';
import { useViewportSize } from '@/shared/hooks/useViewportSize';
import { DocumentElement } from '@/open-api';

interface BreadcrumbProps {
  parentFolders: DocumentElement[];
  onFolderClick: (id: string) => void;
}

export function BreadcrumbsUpdated({
  parentFolders = [],
  onFolderClick,
}: Readonly<BreadcrumbProps>): JSX.Element {
  const { width } = useViewportSize();

  return (
    <nav className="breadcrumbs">
      <button type="button" onClick={() => onFolderClick('root')}>
        <Icon id="folder-solid" size="md" />
      </button>
      <span>/</span>
      {parentFolders.length > 0 &&
        parentFolders.map((f, i) => (
          <React.Fragment key={f.id}>
            {i < parentFolders.length - 1 ? (
              <>
                <button
                  type="button"
                  onClick={() => onFolderClick(f.id as string)}
                  value={f.id}
                >
                  {width < 768 ? (
                    '…'
                  ) : (
                    <span
                      style={{
                        display: 'inline-block',
                        pointerEvents: 'none',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                      }}
                    >
                      {f.name}
                    </span>
                  )}
                </button>
                <span>/</span>
              </>
            ) : (
              <span key={f.id}>{f.name}</span>
            )}
          </React.Fragment>
        ))}
    </nav>
  );
}
