import { Button, Icon } from '@cae/cobalt-react';

export function UploadApprovalLettersButton({
  permitted = false,
  className,
  disabled = false,
  onUploadApprovalLettersClick,
  count,
}: Readonly<{
  notificationContainerId?: string;
  permitted?: boolean;
  className?: string;
  disabled?: boolean;
  onUploadApprovalLettersClick: React.MouseEventHandler<HTMLButtonElement>;
  count?: number;
}>): JSX.Element {
  if (!permitted) return <></>;

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    onUploadApprovalLettersClick(event);
  };

  const rootProps = {
    className,
  };

  return (
    <div {...rootProps}>
      <Button
        type="button"
        variant="secondary"
        size="md"
        onClick={handleClick}
        title="Download document"
        disabled={disabled}
      >
        <Icon id="upload-outline" size="md" />
        Upload Approval Letters ({count ?? 0})
      </Button>
    </div>
  );
}

UploadApprovalLettersButton.displayName = 'UploadApprovalLettersButton';
