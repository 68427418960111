import {
  DocumentsHomePage,
  DocumentsPage,
  SubmittedDocumentsPage,
  DocumentsUploadPage,
} from '@/pages/documents';

import {
  DocumentsOverviewRequestPage,
  DocumentsOverviewUploadPage,
  EasaPage,
  EasaIndexPage,
  FaaPart135Page,
  FaaPart135IndexPage,
} from '@/pages/documents-overview';

import {
  DocumentDetailsRequestPage,
  DocumentDetailsPage,
  DocumentOverviewPage,
} from '@/pages/document-details';
import CAEDocumentsPageWrapper from '@/pages/documents/CAEDocumentsPageWrapper';
import CompanyDocumentsPageWrapper from '@/pages/documents/CompanyDocumentsPageWrapper';

import { CustomerDocumentsPage } from '@/pages/documents/CustomerDocuments';

// ----------------------------------------------------|
//                                                    |
//   URL structure                                    |
//                                                    |
// ----------------------------------------------------|
//   /documents                                       |
// ----------------------------------------------------|
//   /documents/overview                              | Documents Overview
//                                                    |
//   /documents/overview/easa-program                 | EASA program: Index page
//   /documents/overview/easa-program/request         | ............: Change request
//   /documents/overview/easa-program/submit          | ............: Upload documents page
//                                                    |
//   /documents/overview/faa-part135-program          | FAA Part 135 program: Index page
//   /documents/overview/faa-part135-program/request  | ....................: Change request page
//   /documents/overview/faa-part135-program/submit   | ....................: Upload documents page
// ----------------------------------------------------|
//   /documents/company                               | Customer documents: Index page
//   /documents/company/submit                        | ..................: Upload documents page
//   /documents/company/:documentId                   | ..................: Document details page
//   /documents/company/:documentId/request           | ..................: Change request page
// ----------------------------------------------------|
//   /documents/cae                                   | CAE documents: Index page
//   /documents/cae/submit                            | .............: Upload documents page
//   /documents/cae/:documentId                       | .............: Document details page
//   /documents/cae/:documentId/request               | .............: Change request page
// ----------------------------------------------------|
//   /documents/submitted                             | Submitted documents: Index page
//   /documents/submitted/submit                      | ...................: Upload documents page
//   /documents/submitted/:documentId                 | ...................: Document details page
//   /documents/submitted/:documentId/request         | ...................: Change request page
// ----------------------------------------------------|

const documentDetailsRoutes = [
  { path: 'request', element: <DocumentDetailsRequestPage /> },
  {
    index: true,
    element: <DocumentOverviewPage />,
  },
];

export const documentsRoutes = {
  path: 'documents',
  element: <DocumentsPage />,
  children: [
    {
      element: <DocumentsHomePage />,
      path: 'overview/*',
      children: [
        { path: 'submit', element: <DocumentsOverviewUploadPage modal /> },

        // EASA Program
        {
          path: 'easa-program',
          element: <EasaPage />,
          children: [
            { path: 'request', element: <DocumentsOverviewRequestPage /> },
            {
              path: 'submit',
              element: <DocumentsOverviewUploadPage />,
            },
            {
              element: <EasaIndexPage />,
              index: true,
            },
          ],
        },

        // FAA Part 135 Program
        {
          path: 'faa-part135-program',
          element: <FaaPart135Page />,
          children: [
            {
              path: 'request',
              element: <DocumentsOverviewRequestPage />,
            },
            {
              path: 'submit',
              element: <DocumentsOverviewUploadPage />,
            },
            {
              element: <FaaPart135IndexPage />,
              index: true,
            },
          ],
        },

        // Document submission
        {
          path: ':documentId',
          element: <DocumentDetailsPage />,
          children: [...documentDetailsRoutes],
        },
      ],
    },
    {
      path: 'company/*',
      element: <CompanyDocumentsPageWrapper />,
      children: [
        { path: 'submit', element: <DocumentsUploadPage /> },
        {
          path: ':documentId',
          element: <DocumentDetailsPage />,
          children: [...documentDetailsRoutes],
        },
      ],
    },
    {
      path: 'all-companies/*',
      element: <CustomerDocumentsPage />,
      children: [
        { path: 'submit', element: <DocumentsUploadPage /> },
        {
          path: ':documentId',
          element: <DocumentDetailsPage />,
          children: [...documentDetailsRoutes],
        },
      ],
    },
    {
      path: 'cae/*',
      element: <CAEDocumentsPageWrapper />,
      children: [
        { path: 'submit', element: <DocumentsUploadPage /> },
        {
          path: ':documentId',
          element: <DocumentDetailsPage />,
          children: [...documentDetailsRoutes],
        },
      ],
    },
    {
      path: 'submitted/*',
      element: <SubmittedDocumentsPage />,
      children: [
        { path: 'submit', element: <DocumentsUploadPage /> },
        {
          path: ':documentId',
          element: <DocumentDetailsPage />,
          children: [...documentDetailsRoutes],
        },
      ],
    },
  ],
};
